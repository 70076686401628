// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
import LanguageDetector from 'i18next-browser-languagedetector';

export const detector = new LanguageDetector(undefined, {
	// order and from where user language should be detected
	order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

	// keys or params to lookup language from
	lookupQuerystring: 'lng',
	lookupCookie: 'lng',
	lookupLocalStorage: 'lng',
	lookupSessionStorage: 'lng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,

	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// optional expire and domain for set cookie
	// cookieMinutes: 10,
	// cookieDomain: document.location.hostname,

	// optional htmlTag with lang attribute, the default is:
	htmlTag: document.documentElement,

	// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
	cookieOptions: { path: '/', sameSite: 'strict', secure: true },
})
