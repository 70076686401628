const name = 'AES-GCM';
const uuid = '785ba40b-3589-4265-97d5-df8de9b873d8';
const salt = new Uint8Array(16);

const enc = new TextEncoder();
const dec = new TextDecoder();

let iv = new Uint8Array(12); // 96-bit nonce
let key: CryptoKey;


const generateKey = async () => {
	iv.set(enc.encode(uuid.replace('-', '').substring(16, 28)), 0)
	salt.set(enc.encode(uuid.replace('-', '').substring(0, 16)), 0)

	return key = await crypto.subtle.importKey(
    'raw',
    salt,
    { name },
    false,
    ['encrypt', 'decrypt']
  );
}


export const encrypt = async (data: string) => {	

	if (!data)
		return null;
	else if (!key)
		await generateKey()
	
  return (
		crypto.subtle.encrypt(
			{ name, iv },
			key,
			enc.encode(data)
		).then(buffer => btoa(
			new Uint8Array(buffer).reduce((binary, byte) => binary + String.fromCharCode(byte), '')
		))
	)
}


export const decrypt = async (data: string | null) => {
	if (!data)
		return null;
	else if (!key)
		await generateKey()

	// Reverse the process by decoding the base64 string
	const decodedData = atob(data);

	// Convert the decoded string back to an ArrayBuffer
	const arrayBuffer = new Uint8Array(decodedData.length);
	for (let i = 0; i < decodedData.length; i++) {
		arrayBuffer[i] = decodedData.charCodeAt(i);
	}

  return (
		crypto.subtle.decrypt(
			{ name, iv },
			key,
			arrayBuffer
		).then(buffer => dec.decode(buffer))
	)
}
