import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { backend, detector } from './config';

import startCase from 'lodash/startCase';
import { supportedLanguages } from './languages';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

i18n
  .use(backend)
	.use(detector)
  .use(initReactI18next) 																				// passes i18n down to react-i18next
  .init({																												// Init i18next, for all options read: https://www.i18next.com/overview/configuration-options
		// Logging
		// =======
		// debug: false,


		// Languages, namespaces, resources
		// ================================
		// resources: undefined,
		// partialBundledLanguages: false,

		// lng: undefined,																					// If set to 'cimode' the output text will be the key.
		fallbackLng: supportedLanguages[0].code,										// Setting it explicitly to false will not trigger to load the fallbackLng at all. See the Fallback docs.
		supportedLngs: supportedLanguages.map(lng => lng.code),

		// nonExplicitSupportedLngs: false,
		// load: 'all',
		// preload: false,
		// lowerCaseLng: false,
		// cleanCode: false,

		ns: 'page',
		defaultNS: 'page',
		// fallbackNS: false,


		// Missing keys
		// ============
		saveMissing: true,
		saveMissingPlurals: true,
		updateMissing: true,
		saveMissingTo: 'current', 																	// 'current' will use the current language (i18next.language) and 'all' will save it to all languages included in i18next.languages.

		// missingKeyNoValueFallbackToKey: false,
		// missingKeyHandler: false,

		parseMissingKeyHandler: (key, defaultValue?) => defaultValue || startCase(key),

		// appendNamespaceToMissingKey: false,
		// missingInterpolationHandler: undefined,


		// Translation defaults
		// ====================
		// simplifyPluralSuffix: true,															// (used in format < format v4) **
		// postProcess: false,
		// postProcessPassResolved: false,
		// returnNull: false,
		// returnEmptyString: true,
		// returnObjects: false,
		// returnDetails: false,
		// returnedObjectHandler: undefined,
		// joinArrays: false,
		// overloadTranslationOptionHandler: undefined,
    interpolation: {
			// format: undefined,
			// formatSeparator: ',',
			// escape: undefined,
			// alwaysFormat: false,

			escapeValue: false, 																			// react already safes from xss

			// useRawValueToEscape: false,
			// prefix: '{{',
			// suffix: '}}',
			// prefixEscaped: undefined,
			// suffixEscaped: undefined,
			// unescapeSuffix: undefined,
			// unescapePrefix: '-',
			// nestingPrefix: '$t(',
			// nestingSuffix: ')',
			// nestingPrefixEscaped: undefined,
			// nestingSuffixEscaped: undefined,
			// nestingOptionsSeparator: ',',
			// defaultVariables: undefined,
			// maxReplaces: 1000,
			// skipOnVariables: true,
    },
		

		// Others
		// ======
		react: {
			// nsMode: 'default',
			// defaultTransParent: 'div',
			// bindI18n: 'languageChanged',
			// bindI18nStore: '',
			// transEmptyNodeValue: undefined,

			useSuspense: false,

			// hashTransKey: undefined,
			// transSupportBasicHtmlNodes: true,
			// transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
			// transWrapTextNodes: '',
			// keyPrefix: undefined,
			// unescape: undefined,			
		},

		// initImmediate: true,																			// This option only works for sync (blocking) loading backend, like i18next-fs-backend and i18next-sync-fs-backend!
		// keySeparator: '.',
		// nsSeparator: ':',
		// pluralSeparator: '_',
		// contextSeparator: '_',
		// appendNamespaceToCIMode: false,
		// compatibilityJSON?: 'v4',

		// locizeLastUsed: {
		// 	projectId: '',
		// 	apiKey: '',
		// 	referenceLng: 'en',
		// 	version: 'latest',
		// 	debounceSubmit: 90000,
		// 	allowedHosts: ['localhost'],
		// },
	
		// ignoreJSONStructure: true,
		// maxParallelReads: 10,
		// maxRetries: 5,
		// retryTimeout: 350,
  });

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

i18n.on('languageChanged', (locale) => {
	document.documentElement.lang = locale;
	document.body.dir = i18n.dir(locale);

	const lang = supportedLanguages.find(lng => lng.code === locale)
	if (lang && typeof lang.lazy === 'function')
		lang.lazy()
})

export default i18n;