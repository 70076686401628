// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
import Backend from 'i18next-http-backend';

Backend.prototype.loadUrl = function (url, callback) {
	import(`../locale/${url}`).then((resource) => {
		callback(null, resource.default)
	}).catch(err => {
		callback(err, false);
	})
}

export const backend = new Backend(undefined, {
	// path where resources get loaded from, or a function
	// returning a path:
	// function(lngs, namespaces) { return customPath; }
	// the returned path will interpolate lng, ns if provided like giving a static path
	// the function might return a promise
	// returning falsy will abort the download
	//
	// If allowMultiLoading is false, lngs and namespaces will have only one element each,
	// If allowMultiLoading is true, lngs and namespaces can have multiple elements
	loadPath: '{{ns}}.{{lng}}',

	// path to post missing resources, or a function
	// function(lng, namespace) { return customPath; }
	// the returned path will interpolate lng, ns if provided like giving a static path
	addPath: '/api/application/locales/{{lng}}',
})
