// src/axios.js
import Axios from 'axios';



export const axios = Axios.create({
  baseURL: process.env.PUBLIC_URL || (process.env.NODE_ENV === 'development' ? '/mockApi/' : '/api/'),
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout,
  headers: {
    // 'Accept': 'text/html,application/json',
    // 'Accept': 'application/json,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
    // 'Content-Type': 'application/json',
    'X-Powered-By': 'https://www.mo-a.me/',
    'X-Requested-With': 'XMLHttpRequest'
  },
});