import { createContext, PropsWithChildren, useContext, memo, useState, useCallback, useMemo, FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { RootState, selectEnvValue, setEnvValue } from '@Plugin/store';
import { updateLocale } from '@Plugin/i18n';

import { EnvironmentStruc } from './utils';

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const ThemeEnvironmentContext = createContext<ThemeEnvironmentContext>({
	path: '/',
	type: 'web',
	apps: [],
	lngs: [],

	app: '',

	setValue: () => void 0,
});

const pickValue = <T=any>(arr: T[], el: T, allowNull = false) => arr.includes(el) ? el : (allowNull ? undefined : arr[0])

// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const ThemeEnvironmentProvider = memo<PropsWithChildren<PrimaryProps>>(({ children, ...primary }) => {

	const [env,] = useState<PrimaryProps>(primary)

	const group = useMemo(() => 'user', []);

	// ** --------------------------------------------------------------------------------------------------------- ** \\
	
	const dispatch = useDispatch();

	const fn = useCallback((state: RootState) => {

		const app = pickValue(env.apps ?? [], selectEnvValue(state.env, group, 'app', env)) || ''

		const lngs = ((env.type === 'web' ? state.app.langs : state.app.platforms.find(({ id }) => id === app)?.langs) ?? [])

		updateLocale(env.type, lngs, pickValue(lngs, selectEnvValue(state.env, group, 'lng', { ...env, app }), true))

		return {
			app,
			lngs,
		}
	}, [env, group]);

	const { app, lngs } = useSelector(fn)

	// ** --------------------------------------------------------------------------------------------------------- ** \\

	const setValue = useCallback<SetValue>((key, value) => dispatch(setEnvValue({
		env: { ...env, app },
		key,
		value,
		group,
	})), [dispatch, env, app, group])

	// ** --------------------------------------------------------------------------------------------------------- ** \\
	// ** --------------------------------------------------------------------------------------------------------- ** \\

	return (
		<ThemeEnvironmentContext.Provider
			value={{
				...env,
				app,
				lngs,
				setValue
			}}
		>
			{children ?? <Outlet />}
		</ThemeEnvironmentContext.Provider>
	)
})

export const useThemeEnvironment = () => useContext<ThemeEnvironmentContext>(ThemeEnvironmentContext);

export const createThemeEnvironment = ({ Component, ...primary }: PrimaryProps & { Component?: FC }) => {

	const children = !Component ? null : <Component />

	return (props: PropsWithChildren) => (
		<ThemeEnvironmentProvider {...primary} children={children} {...props} />
	)
}

interface PrimaryProps extends Pick<EnvironmentStruc, 'path' | 'type'> {
	apps: Array<string>;	// Ids for apps with same name
}

type RuntimeProps = Pick<EnvironmentStruc, 'app' | 'lng'>

// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
interface ThemeEnvironmentContext extends PrimaryProps, Omit<RuntimeProps, 'lng'> {
	lngs: Array<string>;

	setValue: SetValue
}

interface SetValue<T extends RuntimeProps = RuntimeProps> {
	<K extends keyof T>(key: K, value: T[K]): void
}
