import { createThemeEnvironment } from '@Plugin/env';
import { ThemeProvider } from '@Plugin/theme';

import { buildDemoRoutes } from './buildDemoRoutes';
import { buildWebsiteRoutes } from './buildWebsiteRoutes';
import { buildPlatformRoutes } from './buildPlatformRoutes';
import { buildTemplateRoutes } from './buildTemplateRoutes';

import { RoutesBuilder } from './utils';

export const buildRoutes: RoutesBuilder = (instance) => {

	// Initial with demo routes for test porpuses
	const routes = buildDemoRoutes(instance)

	// ** ---- Start: Append web routes for the instance ---------------------------------------------------------- ** \\
	if (instance.route?.home) {
		const webRoutes = buildWebsiteRoutes(instance);

		if (webRoutes.length) {
			routes.push({
				path: instance.route.home,
				Component: createThemeEnvironment({
					type: 'app',
					path: instance.route.home,
					apps: [],
					Component: ThemeProvider,
				}),
				children: webRoutes,
			})
		}
	}
	// ** ---- Final: Append app routes for the instance ---------------------------------------------------------- ** \\

	// ** --------------------------------------------------------------------------------------------------------- ** \\

	// ** ---- Start: Append app routes for each platform --------------------------------------------------------- ** \\
	const groups = instance.platforms.reduce((previous, current) => {
		if (current.path) {
			if (previous.has(current.path)) {
				(previous.get(current.path) ?? []).push(current.id)
			} else {
				previous.set(current.path, [current.id])
			}
		}
		
		return previous;
	}, new Map<string, string[]>())

	const appRoutes = groups.size > 0 ? buildPlatformRoutes(instance) : [];

	if (appRoutes.length) {
		groups.forEach((apps, path) => {
			routes.push({
				path,
				Component: createThemeEnvironment({
					type: 'app',
					path,
					apps,
					Component: ThemeProvider,
				}),
				children: appRoutes,
			})
		})
	}
	// ** ---- Final: Append app routes for each platform --------------------------------------------------------- ** \\
	
	// ** --------------------------------------------------------------------------------------------------------- ** \\

	// ** ---- Start: Append routes for theme template ------------------------------------------------------------ ** \\
	// routes.push(...buildTemplateRoutes(instance));
	routes.push({
		path: '/template',
		Component: createThemeEnvironment({
			type: 'app',
			path: '/template',
			apps: instance.platforms.map(x => x.id),
			Component: ThemeProvider,
		}),
		children: buildTemplateRoutes(instance),
	})
	// ** ---- Final: Append routes for theme template ------------------------------------------------------------ ** \\

	return routes;
}

interface RouteInfo {
	title?: string;
}

declare module 'react-router-dom' {
	interface IndexRouteObject {
		meta?: RouteInfo;
	}
	interface NonIndexRouteObject {
		meta?: RouteInfo;
	}
}
