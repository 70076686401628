import { ActionCreatorWithPayload, Middleware } from '@reduxjs/toolkit';


export const LOAD_INITIAL_STATE = 'LOAD_INITIAL_STATE'


const isAppAction = (action: AppAction): action is AppAction => {
  return ('type' in action) && !!(action.type);
}

export const createPersistMiddleware = (config: PersistConfig[]) => {
	
	var nIntervalId: NodeJS.Timeout | undefined;
	var nTicksCount: number = 0;
	var nSuspending: number = 0;
	
	const MAX_TICKS = 10 // Every 10 seconds

	var aConfigures: InternalPersistConfig[];
	var oRecentData: Record<string, Required<PersistConfig['initial']>>;

	const initConfig = (config: PersistConfig, initialState: any) => !config.reducer ? [] : ({
		reducer: config.reducer,
		saveAs: config.saveAs || config.reducer,
		ignore: [config.ignore || []].flat(),
		initial: config.initial || initialState[config.reducer],
		persist: {},
		assignFn: config.assignFn,
	});

	// Create an object contains blacklist properties with current values
	const mapIgnores = (props: string[], data: Record<string, any>) => {
		const ignores = {} as any
		props.forEach(prop => {
			ignores[prop] = data[prop]
		})

		return ignores
	}

	const startTimer = (data?: typeof oRecentData, bImmediate?: boolean) => {

		if (data)
			oRecentData = data

		const onTick = () => {
			nTicksCount++;

			// Wait n seconds for auto save
			if (nTicksCount < MAX_TICKS) {
				return;
			}

			// Stop the timer
			clearInterval(nIntervalId)
			nTicksCount = 0
			nIntervalId = undefined

			// Check changes
			aConfigures.forEach(c => {

				// Create an object with changed properties
				const changes = Object.discardDefaults(
					Object.assign({}, oRecentData[c.reducer], mapIgnores(c.ignore, c.initial)),
					c.initial
				)

				// Save data
				if (!Object.isEqual(changes, c.persist)) {
					// Update [persist] with persisted state
					localStorage.setJson(c.saveAs, c.persist = changes , { crypt: false, })
				}
			})
		}

		if (bImmediate) { // Force
			nTicksCount = MAX_TICKS
			onTick()
		} else if (nIntervalId) { // Timer is running
			nTicksCount = 0;
		} else {
			nIntervalId = setInterval(onTick, 1000) // Every 1 second
		}
	}

	const middleware: Middleware = (store) => (next) => (action: any) => {
		if (isAppAction(action) && action.type === LOAD_INITIAL_STATE) {
			const state = store.getState()

			// Fix reducer settings and Read initial state
			aConfigures = [config || []].flat().map(c => initConfig(c, state)).flat()

			nSuspending++;

			// Read pre-saved data
			Promise.all(
				aConfigures.map(c => (
					localStorage.getJson(c.saveAs, { crypt: false }).then(data => {
						// Assign pre-saved data into reducer state and the internal configurations for next comparision
						return data && store.dispatch(c.assignFn(c.persist = Object.assign(data, mapIgnores(c.ignore, c.initial))))
					})
				))
			).finally(() => {
				nSuspending--
			})
		}
		
		// Execute original action
		const result = next(action);

		// Check action if related to associcated reducers or to general
		const reducerName = action.type.split('/')
		if (nSuspending === 0 && (nIntervalId || reducerName.length === 1 || aConfigures.findIndex(c => c.reducer === reducerName[0]) > -1)) {
			// Start countdown to save
			startTimer(store.getState());
		}

		return result;
	};

	// Auto save data on leaving the application
	const onBeforeUnload = () => {
		if (nIntervalId) {
			startTimer(undefined, true)
		}
		window.removeEventListener('beforeunload', onBeforeUnload);
	}
	window.addEventListener('beforeunload', onBeforeUnload)

	return middleware
}

interface PersistConfig {
	reducer: string;
	saveAs?: string;
	ignore?: string[];
	initial?: Record<string, any>
	assignFn: ActionCreatorWithPayload<Record<string, any>>
}

interface InternalPersistConfig extends Required<PersistConfig> {
	persist: Record<string, any>
}

interface AppAction {
	type: string;
	payloads: any
}