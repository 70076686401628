const numbers: Record<string, number> = {
	'NAN': NaN,
	'INFINITY': Infinity,
	'-INFINITY': -Infinity,
	'+INFINITY': Infinity,
}

export const toNumber = <T = number>(value: any, defaultValue: T): number | T => {
	if (
		typeof value === 'number' ||
		value instanceof Number ||
		(isFinite(value) && String(value).trim().length > 0)
	)
		return Number(value)
	else
		return numbers[String(value).trim().toUpperCase()] ?? defaultValue
}
