import {  IsEqual, discardDefaults, getTypeName,  isEmpty,  isEqual,  isObject, isPlainObject } from './helpers'


Object.isEqual = isEqual
Object.isEmpty = isEmpty
Object.isObject = isObject
Object.isPlainObject = isPlainObject

Object.getTypeName = getTypeName
Object.discardDefaults = discardDefaults


declare global {
  interface ObjectConstructor {
		isEqual: IsEqual<any>
		isEmpty: typeof isEmpty;
		isObject: typeof isObject;
		isPlainObject: typeof isPlainObject;

		getTypeName: typeof getTypeName;
		discardDefaults: typeof discardDefaults;
  }
}