export const supportedLanguages: Language[] = [
  {
		code: 'ar-EG',
    text: 'العربية',
		lazy: () => import('dayjs/locale/ar'),
  },
  {
		code: 'en-US',
    text: 'English',
		lazy: () => import('dayjs/locale/en'),
  },
  {
		code: 'de-DE',
    text: 'Deutsch',
		lazy: () => import('dayjs/locale/de'),
  },
  {
		code: 'es-ES',
    text: 'Español',
		lazy: () => import('dayjs/locale/es'),
  }
]

export const getFallbackLng = (lngs: LanguageCode[]) => lngs.find(lng => supportedLanguages.find(l => l.code === lng)) ?? lngs[0]

type LanguageCode = 'ar-EG' | 'en-US' | 'es-ES' | 'de-DE'

export interface Language {
	code: LanguageCode,
	text: string,
	lazy: () => void
}