// https://fontsource.org/fonts/cabin
// yarn add @fontsource/noto-kufi-arabic @fontsource/noto-naskh-arabic @fontsource/almarai @fontsource/markazi-text @fontsource/raleway @fontsource/public-sans

import { Theme as MuiTheme, ThemeOptions, UseMediaQueryOptions, darken } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';

import getPalette, { UIStyle } from './palette';

// TODO: Search for [// TODO: Review]

import '@fontsource/noto-kufi-arabic'
import '@fontsource/roboto'

// import '@fontsource/noto-kufi-arabic/metadata.json'

import './style.sass'

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const createThemeOptions: CreateThemeOptions = (direction, { paletteName, colorScheme, alterScheme }) => {

  const mode = colorScheme || alterScheme
  const palette = getPalette(paletteName, mode, false)

  const majorGradientColor = mode === 'dark' ? darken(palette.primary.main, 0.6) : palette.primary.main
  const minorGradientColor = mode === 'dark' ? darken(palette.secondary.main, 0.6) : palette.secondary.main

  // export const gradientBgLight = (theme) => `linear-gradient(-45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 33%, ${theme.palette.secondary.main} 100%);`;
  // export const gradientBgDark = (theme) => `linear-gradient(-45deg, ${darken(theme.palette.primary.main, 0.4)} 0%, ${darken(theme.palette.primary.main, 0.4)} 33%, ${darken(theme.palette.secondary.main, 0.4)} 100%);`;

  // solid: majorGradientColor,
  // gradient: `linear-gradient(-45deg, ${majorGradientColor} 0%, ${majorGradientColor} 33%, ${minorGradientColor} 100%)`
  // gradientBackground: {
  //   background: theme.palette.mode === 'dark' ? gradientBgDark(theme) : gradientBgLight(theme),
  // },
  // solidBackground: {
  //   background: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
  // },

  const options: CustomThemeOptions = {
    direction,
    palette: {
      mode,
      primary: palette.primary,
      secondary: palette.secondary,
      action: {
        hover: mode === 'dark' ? 'rgba(80,80,80, 0.9)' : 'rgba(80,80,80, 0.05)',
        hoverOpacity: 0.05
      },
      background: {
        paper: mode === 'dark' ? '#424242' : '#FFFFFF',
        default: mode === 'dark' ? '#303030' : '#F0F0F0',
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"Noto Kufi Arabic2"',
        // '"Roboto"',
        '"Open Sans"',
        'sans-serif',
      ].join(','),
      title: {
        fontWeight: 600
      },
      body2: {
        fontWeight: 500
      },
      fontWeightMedium: 600
    },
    shade: {
      light: '0 10px 15px -5px rgba(62, 57, 107, .07)',
    },
    glow: {
      light: `0 2px 20px -5px ${palette.primary.main}`,
      medium: `0 2px 40px -5px ${palette.primary.main}`,
      dark: `0 2px 40px 0px ${palette.primary.main}`,
    },
    rounded: {
      small: '8px',
      medium: '12px',
      big: '20px',
    },
    shadows: mode === 'dark'
      ? [
        'none',
        '0px 1px 3px 0px rgba(50,50,50, 0.2),0px 1px 1px 0px rgba(50,50,50, 0.14),0px 2px 1px -1px rgba(50,50,50, 0.12)',
        '0px 1px 5px 0px rgba(50,50,50, 0.2),0px 2px 2px 0px rgba(50,50,50, 0.14),0px 3px 1px -2px rgba(50,50,50, 0.12)',
        '0px 1px 8px 0px rgba(50,50,50, 0.2),0px 3px 4px 0px rgba(50,50,50, 0.14),0px 3px 3px -2px rgba(50,50,50, 0.12)',
        '0px 2px 4px -1px rgba(50,50,50, 0.2),0px 4px 5px 0px rgba(50,50,50, 0.14),0px 1px 10px 0px rgba(50,50,50, 0.12)',
        '0px 3px 5px -1px rgba(50,50,50, 0.2),0px 5px 8px 0px rgba(50,50,50, 0.14),0px 1px 14px 0px rgba(50,50,50, 0.12)',
        '0px 3px 5px -1px rgba(50,50,50, 0.2),0px 6px 10px 0px rgba(50,50,50, 0.14),0px 1px 18px 0px rgba(50,50,50, 0.12)',
        '0px 4px 5px -2px rgba(50,50,50, 0.2),0px 7px 10px 1px rgba(50,50,50, 0.14),0px 2px 16px 1px rgba(50,50,50, 0.12)',
        '0px 5px 5px -3px rgba(50,50,50, 0.2),0px 8px 10px 1px rgba(50,50,50, 0.14),0px 3px 14px 2px rgba(50,50,50, 0.12)',
        '0px 5px 6px -3px rgba(50,50,50, 0.2),0px 9px 12px 1px rgba(50,50,50, 0.14),0px 3px 16px 2px rgba(50,50,50, 0.12)',
        '0px 6px 6px -3px rgba(50,50,50, 0.2),0px 10px 14px 1px rgba(50,50,50, 0.14),0px 4px 18px 3px rgba(50,50,50, 0.12)',
        '0px 6px 7px -4px rgba(50,50,50, 0.2),0px 11px 15px 1px rgba(50,50,50, 0.14),0px 4px 20px 3px rgba(50,50,50, 0.12)',
        '0px 7px 8px -4px rgba(50,50,50, 0.2),0px 12px 17px 2px rgba(50,50,50, 0.14),0px 5px 22px 4px rgba(50,50,50, 0.12)',
        '0px 7px 8px -4px rgba(50,50,50, 0.2),0px 13px 19px 2px rgba(50,50,50, 0.14),0px 5px 24px 4px rgba(50,50,50, 0.12)',
        '0px 7px 9px -4px rgba(50,50,50, 0.2),0px 14px 21px 2px rgba(50,50,50, 0.14),0px 5px 26px 4px rgba(50,50,50, 0.12)',
        '0px 8px 9px -5px rgba(50,50,50, 0.2),0px 15px 22px 2px rgba(50,50,50, 0.14),0px 6px 28px 5px rgba(50,50,50, 0.12)',
        '0px 8px 10px -5px rgba(50,50,50, 0.2),0px 16px 24px 2px rgba(50,50,50, 0.14),0px 6px 30px 5px rgba(50,50,50, 0.12)',
        '0px 8px 11px -5px rgba(50,50,50, 0.2),0px 17px 26px 2px rgba(50,50,50, 0.14),0px 6px 32px 5px rgba(50,50,50, 0.12)',
        '0px 9px 11px -5px rgba(50,50,50, 0.2),0px 18px 28px 2px rgba(50,50,50, 0.14),0px 7px 34px 6px rgba(50,50,50, 0.12)',
        '0px 9px 12px -6px rgba(50,50,50, 0.2),0px 19px 29px 2px rgba(50,50,50, 0.14),0px 7px 36px 6px rgba(50,50,50, 0.12)',
        '0px 10px 13px -6px rgba(50,50,50, 0.2),0px 20px 31px 3px rgba(50,50,50, 0.14),0px 8px 38px 7px rgba(50,50,50, 0.12)',
        '0px 10px 13px -6px rgba(50,50,50, 0.2),0px 21px 33px 3px rgba(50,50,50, 0.14),0px 8px 40px 7px rgba(50,50,50, 0.12)',
        '0px 10px 14px -6px rgba(50,50,50, 0.2),0px 22px 35px 3px rgba(50,50,50, 0.14),0px 8px 42px 7px rgba(50,50,50, 0.12)',
        '0px 11px 14px -7px rgba(50,50,50, 0.2),0px 23px 36px 3px rgba(50,50,50, 0.14),0px 9px 44px 8px rgba(50,50,50, 0.12)',
        '0px 11px 15px -7px rgba(50,50,50, 0.2),0px 24px 38px 3px rgba(850,50,50 0.14),0px 9px 46px 8px rgba(50,50,50, 0.12)',
      ]
      : [
        'none',
        '0px 1px 3px 0px rgba(80,80,80, 0.2),0px 1px 1px 0px rgba(80,80,80, 0.14),0px 2px 1px -1px rgba(80,80,80, 0.12)',
        '0px 1px 5px 0px rgba(80,80,80, 0.2),0px 2px 2px 0px rgba(80,80,80, 0.14),0px 3px 1px -2px rgba(80,80,80, 0.12)',
        '0px 1px 8px 0px rgba(80,80,80, 0.2),0px 3px 4px 0px rgba(80,80,80, 0.14),0px 3px 3px -2px rgba(80,80,80, 0.12)',
        '0px 2px 4px -1px rgba(80,80,80, 0.2),0px 4px 5px 0px rgba(80,80,80, 0.14),0px 1px 10px 0px rgba(80,80,80, 0.12)',
        '0px 3px 5px -1px rgba(80,80,80, 0.2),0px 5px 8px 0px rgba(80,80,80, 0.14),0px 1px 14px 0px rgba(80,80,80, 0.12)',
        '0px 3px 5px -1px rgba(80,80,80, 0.2),0px 6px 10px 0px rgba(80,80,80, 0.14),0px 1px 18px 0px rgba(80,80,80, 0.12)',
        '0px 4px 5px -2px rgba(80,80,80, 0.2),0px 7px 10px 1px rgba(80,80,80, 0.14),0px 2px 16px 1px rgba(80,80,80, 0.12)',
        '0px 5px 5px -3px rgba(80,80,80, 0.2),0px 8px 10px 1px rgba(80,80,80, 0.14),0px 3px 14px 2px rgba(80,80,80, 0.12)',
        '0px 5px 6px -3px rgba(80,80,80, 0.2),0px 9px 12px 1px rgba(80,80,80, 0.14),0px 3px 16px 2px rgba(80,80,80, 0.12)',
        '0px 6px 6px -3px rgba(80,80,80, 0.2),0px 10px 14px 1px rgba(80,80,80, 0.14),0px 4px 18px 3px rgba(80,80,80, 0.12)',
        '0px 6px 7px -4px rgba(80,80,80, 0.2),0px 11px 15px 1px rgba(80,80,80, 0.14),0px 4px 20px 3px rgba(80,80,80, 0.12)',
        '0px 7px 8px -4px rgba(80,80,80, 0.2),0px 12px 17px 2px rgba(80,80,80, 0.14),0px 5px 22px 4px rgba(80,80,80, 0.12)',
        '0px 7px 8px -4px rgba(80,80,80, 0.2),0px 13px 19px 2px rgba(80,80,80, 0.14),0px 5px 24px 4px rgba(80,80,80, 0.12)',
        '0px 7px 9px -4px rgba(80,80,80, 0.2),0px 14px 21px 2px rgba(80,80,80, 0.14),0px 5px 26px 4px rgba(80,80,80, 0.12)',
        '0px 8px 9px -5px rgba(80,80,80, 0.2),0px 15px 22px 2px rgba(80,80,80, 0.14),0px 6px 28px 5px rgba(80,80,80, 0.12)',
        '0px 8px 10px -5px rgba(80,80,80, 0.2),0px 16px 24px 2px rgba(80,80,80, 0.14),0px 6px 30px 5px rgba(80,80,80, 0.12)',
        '0px 8px 11px -5px rgba(80,80,80, 0.2),0px 17px 26px 2px rgba(80,80,80, 0.14),0px 6px 32px 5px rgba(80,80,80, 0.12)',
        '0px 9px 11px -5px rgba(80,80,80, 0.2),0px 18px 28px 2px rgba(80,80,80, 0.14),0px 7px 34px 6px rgba(80,80,80, 0.12)',
        '0px 9px 12px -6px rgba(80,80,80, 0.2),0px 19px 29px 2px rgba(80,80,80, 0.14),0px 7px 36px 6px rgba(80,80,80, 0.12)',
        '0px 10px 13px -6px rgba(80,80,80, 0.2),0px 20px 31px 3px rgba(80,80,80, 0.14),0px 8px 38px 7px rgba(80,80,80, 0.12)',
        '0px 10px 13px -6px rgba(80,80,80, 0.2),0px 21px 33px 3px rgba(80,80,80, 0.14),0px 8px 40px 7px rgba(80,80,80, 0.12)',
        '0px 10px 14px -6px rgba(80,80,80, 0.2),0px 22px 35px 3px rgba(80,80,80, 0.14),0px 8px 42px 7px rgba(80,80,80, 0.12)',
        '0px 11px 14px -7px rgba(80,80,80, 0.2),0px 23px 36px 3px rgba(80,80,80, 0.14),0px 9px 44px 8px rgba(80,80,80, 0.12)',
        '0px 11px 15px -7px rgba(80,80,80, 0.2),0px 24px 38px 3px rgba(80,80,80, 0.14),0px 9px 46px 8px rgba(80,80,80, 0.12)',
      ],
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor:
              mode === 'dark'
                ? '#292929'
                : '#FFFFFF'
          },
          rounded: {
            borderRadius: 8,
          },
          elevation1: {
            boxShadow:
              mode === 'dark'
                ? '0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)'
                : '0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
          },
          elevation4: {
            boxShadow:
              mode === 'dark'
                ? '0px 2px 4px -1px rgba(64, 64, 64, 0.46), 0px 4px 5px 0px rgba(42, 42, 42, 0.32), 0px 1px 10px 0px rgba(20, 20, 20, 0.12)'
                : '0px 2px 4px -1px rgba(142, 142, 142, 0.2), 0px 4px 5px 0px rgba(243, 243, 243, 0.14), 0px 1px 10px 0px rgba(204, 204, 204, 0.12)',
          },
        }
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            boxShadow: 'none',
          },
          root: {
            borderRadius: 20,
            fontWeight: 600,
          },
          sizeSmall: {
            padding: '7px 12px',
          },
        }
      },
      MuiTypography: {
        styleOverrides: {
          button: {
            fontWeight: 600,
          },
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            border:
              mode === 'dark'
                ? '1px solid rgba(255,255,255,0.32)'
                : '1px solid rgba(0,0,0,0.32)',
            borderRadius: 8,
            alignItems: 'center',
            transition: 'border 0.3s ease',
          },
          underline: {
            '&[role="radiogroup"]': {
              alignItems: 'flex-start',
            },
            '&:after': {
              height: 'calc(100% + 1px)',
              borderRadius: 8,
              bottom: -1,
              boxShadow: `0 0 1px ${palette.primary.main}`,
            },
            '&:before': {
              display: 'none'
            }
          },
          input: {
            padding: 10,
            fontSize: 14,
          },
          multiline: {
            paddingTop: 24
          },
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            padding: '24px 8px 0',
            top: -3,
            '& $endAdornment': {
              paddingTop: 0
            }
          },
          tag: {
            padding: '0 !important'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          standard: {
            top: 10,
            left: 10,
            transform: 'translate(0, 22px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(0, 13px) scale(0.7)',
              zIndex: 1,
            },
            '& + div': {
              paddingBottom: 4,
              '& input, > .MuiInputAdornment-positionStart, > select': {
                padding: '24px 8px 0',
              },
              '& .MuiSelect-select': {
                paddingBottom: 0,
                paddingTop: '24px'
              },
            },
          },
          outlined: {
            transform: 'translate(14px, 10px) scale(1)',
            '&.MuiInputLabel-shrink': {
              transform: 'translate(14px, -9px) scale(0.75)',
              zIndex: 1,
            },
          },
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 14
          },
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          contained: {
            marginLeft: 4
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          // a1: 'x',
          root: {
            borderRadius: 8,
          },
          icon: {
            top: 'calc(50% - 10px)',
            right: 0,
          },
          // TODO: Review
          // selectMenu: {
          //   paddingRight: '24px',
          // }
        }
      },
      // MuiSvgIcon: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: '1.2rem'
      //     }
      //   }
      // },
      // MuiIcon: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: '1.2rem'
      //     }
      //   }
      // },
      // MuiRating: {
      //   styleOverrides: {
      //     sizeSmall: {
      //       [`.${svgIconClasses.root}`]: {
      //         fontSize: '1.125rem',
      //       }
      //     },
      //     sizeMedium: {
      //       [`.${svgIconClasses.root}`]: {
      //         fontSize: '1.5rem',
      //       }
      //     },
      //     sizeLarge: {
      //       [`.${svgIconClasses.root}`]: {
      //         fontSize: '1.875rem',
      //       }
      //     },
      //   }
      // },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            alignItems: 'flex-end',
            height: 'auto !important',
            width: 32,
            '& button': {
              width: 32,
              height: 32,
              padding: 0,
            },
            '& p': {
              minWidth: 24,
              lineHeight: '16px'
            },
            '& svg': {
              position: 'relative',
              top: 4
            },
            '& .MuiAvatar-root': {
              marginRight: 12
            }
          },
          positionStart: {
            marginLeft: 0,
            marginRight: 0,
            '& + *': {
              paddingLeft: '0 !important'
            }
          },
          positionEnd: {
            marginRight: 0,
            marginLeft: 0
          },
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom:
              mode === 'dark'
                ? '1px solid #636363'
                : `1px solid ${palette.primary.light}`,
          },
          head: {
            fontWeight: 600,
          },
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap'
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
          bar: {
            borderRadius: 16,
          },
          colorPrimary: {
            backgroundColor: mode === 'dark' ? '#616161' : '#ededed',
          },
        }
      },
      // TODO: Review
      // MuiPickersToolbar: {
      //   styleOverrides: {
      //     toolbar: {
      //       borderRadius: 0,
      //       boxShadow: 'inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)',
      //     },
      //   }
      // },

      // TODO: Review
      // MuiPickersClock: {
      //   styleOverrides: {
      //     clock: {
      //       backgroundColor: 'none',
      //       border: `1px solid ${palette.primary.main}`,
      //     },
      //   }
      // },

      // TODO: Review
      // MuiPickersClockPointer: {
      //   styleOverrides: {
      //     thumb: {
      //       boxShadow: `0 1px 10px 0px ${
      //         palette.primary.main
      //       }`,
      //     },
      //   }
      // },

      // TODO: Review
      // MuiPickerDTTabs: {
      //   styleOverrides: {
      //     tabs: {
      //       backgroundColor: 'transparent',
      //       color: palette.primary.main,
      //     },
      //   }
      // },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&:first-of-type': {
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            },
            '&:last-child': {
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            },
            '&$expanded': {
              borderRadius: 16,
              boxShadow: `0px 0px 0px 1px ${palette.primary.main}`,
              '& + div': {
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
              },
            },
          },
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            position: 'relative',
            marginBottom: 32,
            '&:after': {
              content: '""',
              position: 'absolute',
              width: 60,
              height: 4,
              background: palette.primary.main,
              bottom: 0,
              left: 26,
            },
            '& h2': {
              color:
                mode === 'dark'
                  ? palette.primary.light
                  : palette.primary.dark,
            },
          },
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            '@media (min-width: 960px)': {
              borderRadius: 8,
            },
          },
        }
      },
      MuiAppBar: {
        defaultProps: {
          elevation: 0
        },
        styleOverrides: {
          colorPrimary: {
            backgroundColor:
              mode === 'dark'
                ? palette.primary.dark
                : palette.primary.main,
          },
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderRadius: 10,
          },
          indicator: {
            borderRadius: '10px 10px 0 0',
            height: 4,
          },
        }
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
            borderRadius: 8,
            boxShadow: 'none',
            border: `1px solid ${palette.secondary.main}`,
          },
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            height: 32,
            boxShadow: 'none !important',
            '&$selected': {
              color: palette.secondary.main,
              backgroundColor: palette.secondary.light
            }
          },
        }
      },

      // TODO: Review
      // MUIDataTableToolbar: {
      //   styleOverrides: {
      //     filterPaper: {
      //       maxWidth: 'none'
      //     }
      //   }
      // },

      // TODO: Review
      // MUIDataTableToolbarSelect: {
      //   styleOverrides: {
      //     root: {
      //       boxShadow: 'none',
      //       backgroundColor:
      //         mode === 'dark'
      //           ? palette.secondary.dark
      //           : palette.secondary.light,
      //     },
      //     title: {
      //       padding: direction === 'rtl' ? '0 26px 0 0' : '0 0 0 26px',
      //     },
      //     deleteIcon: {
      //       color: mode === 'dark' ? '#FFF' : '#000',
      //     },
      //   }
      // },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            margin: direction === 'rtl' ? '0 -8px 0 4px' : '0 4px 0 -8px'
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            direction: 'ltr'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: '8px 0 8px 8px'
          },
          root: {
            paddingRight: 0
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            flex: 1
          },
          root: {
            MuiTablePagination: {
              input: {
                marginRight: 32,
                marginLeft: 8,
              },
              selectRoot: {
                marginLeft: 0,
                marginRight: 0,
              },
              select: {
                paddingRight: 24,
              },
              selectIcon: {
                top: 4,
              },
              '& p': {
                marginBottom: 0
              }
            },
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            marginBottom: 0
          },
          selectLabel: {
            marginBottom: 0
          }
        }
      },

      // TODO: Review
      // MUIDataTable: {
      //   styleOverrides: {
      //     responsiveStacked: {
      //       overflow: 'auto !important',
      //       overflowX: 'auto !important',
      //     },
      //     tableRoot: {
      //       minWidth: 360
      //     }
      //   }
      // },

      // TODO: Review
      // MUIDataTablePagination: {
      //   styleOverrides: {
      //     toolbar: {
      //       padding: 0,
      //       '& > p': {
      //         marginBottom: 0,
      //         '@media (max-width: 400px)': {
      //           display: 'none'
      //         }
      //       },
      //     },
      //     root: {
      //       padding: 0
      //     }
      //   }
      // }
    },

    // Extra options
    drawer: {
      width: 240
    },
    background: {
      solid: majorGradientColor,
      gradient: `linear-gradient(-45deg, ${majorGradientColor} 0%, ${majorGradientColor} 33%, ${minorGradientColor} 100%)`
    },

    important: <T>(value: T) => `${value}!important` as T,
  }

  return options;
}

type CustomThemeOptions = ThemeOptions & Required<Omit<ThemeOptions, 'breakpoints' | 'mixins' | 'shape' | 'spacing' | 'transitions' | 'unstable_strictMode' | 'unstable_sxConfig' | 'zIndex'>>;

type CreateThemeOptions = (direction: 'ltr' | 'rtl', props: Partial<UIStyle>) => CustomThemeOptions;




interface MOATheme {
  glow: {
    light: string
    medium: string
    dark: string
  }
  shade: {
    light: string
  }
  drawer: {
    width: number
  }
  background: {
    solid: string;
    gradient: string;
  }
  rounded: {
    small: string;
    medium: string;
    big: string;
  }

  important: <T>(value: T) => T;
}

declare module '@mui/system' {
  interface Theme extends MOATheme {}
  interface ThemeOptions extends Partial<MOATheme> {}
  // allow configuration using `createTheme`
  // interface ThemeOptions {
  //   glow: {
  //     light: string
  //     medium: string
  //     dark: string
  //   }
  //   shade: {
  //     light: string
  //   }
  //   rounded: {
  //     // small: string;
  //     // medium: string;
  //     // big: string;
  //   }
  // }
}

declare module '@mui/material/styles' {
  // interface Theme extends MOATheme {}
  // interface ThemeOptions extends Partial<MOATheme> {}

  // interface Components {
  //   MuiSelect?: {
  //     styleOverrides?: {
  //       // Add your additional styleOverrides for MuiSelect here
  //       // For example:
  //       icon?: React.CSSProperties;
  //       // ...
  //     };
  //   };
  // }
}
declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    // TODO: Review
    useNextVariants: boolean;
    title: {
      fontWeight: number
    };
  }
}

declare module '@emotion/react' {
  interface Theme extends MuiTheme {}
}
declare module '@mui/material' {
  function useMediaQuery<Theme = MuiTheme>(queryInput: string | ((theme: Theme) => string), options?: UseMediaQueryOptions): boolean;
}