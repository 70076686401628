class APIStorage {
  private static instance: APIStorage | null = null;
  private headers: Record<string, Record<string, any>> = {};

  private constructor() {}

  static getInstance(): APIStorage {
    if (!APIStorage.instance) {
      APIStorage.instance = new APIStorage();
    }
    return APIStorage.instance;
  }

  getHeader(key: string, name: string): any {
    const keyHeaders = this.headers[key];
    return keyHeaders ? keyHeaders[name] : undefined;
  }

  setHeader(key: string, name: string, value: any): void {
    if (!this.headers[key]) {
      this.headers[key] = {};
    }
    this.headers[key][name] = value;
  }
}

export default APIStorage;