import { map, sum } from 'lodash';
import { Breakpoint } from '@mui/material';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const setup: EnvironmentSetup = {
	app: {
		init: [{ v: '', e: { path: '', type: '*' } }],
		rank: { path: 1, type: 2, },
	},
	lng: {
		init: [{ v: '', e: { path: '', type: '*', app: '*' } }],
		rank: { path: 1, type: 2, app: 3 },
	},
	sidebar: {
		init: [
			{ v: 'wide', e: { path: '*', type: '*', mediaX: '*' } },
		],
		rank: { path: 1, type: 2, mediaX: 4 },
		enum: ['wide', 'tiny', 'hide']
	}
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

const findMatches = <
  K extends keyof EnvironmentState
>(
	key: K,
	env: EnvironmentState[K]['e'],
	state: Array<EnvironmentState[K]>,
	{ exactOnly, itemScope }: MatchOptions = {}
) => {

	const item = setup[key]
  
  // Get nearst value by environment
  return [
		(itemScope === 'init' ? undefined : state) ?? [],
		[],
		(itemScope === 'user' ? undefined : item.init) ?? [],
		[],
	].map((v, i) => {

		const items: Array<MatchItem<K>> = [];
		const every = v.every((value, index) => {
			let exact = true
			let score = sum(map(value.e, <K2 extends keyof EnvironmentState[K]['e'], V2 = EnvironmentState[K]['e'][K2]>(v: V2, k: K2) => {
				exact = exact && v !== '*'
				return (v !== '*' && v !== env[k])
					? (item.rank[k] || 1) * -1
					: 0
			}))
			
			if (exactOnly && score !== 0) {
				return true;
			}

			const order = i + (exact ? 0 : 1)

			items.push({
				...value,
				score, 							// Lower is the worst
				order, 							// { 0: Exact user value, 1: General user value, 2 Exact init, 3 General init }
				index
			})
	
			// Continue looping if didn't completely match
			return score !== 0 || order !== i
		})

		return (!every && items.pop()) || items

	}).flat().sort(
    (a, b) => (b.score - a.score) || (a.order - b.order)
  );
}

const createRecord = <
	K extends keyof EnvironmentState
>(name: K, value: EnvironmentState[K]['v'], state: EnvironmentState[K]['e']) => {
	let e: EnvironmentState[K]['e'] = { ...setup[name].init[0]['e'] }
	let k: keyof typeof e
	for (k in e) {
		Object.assign(e, {
			[k]: state[k] ?? '*'
		})
	}

	return {
		v: value,
		e
	} as EnvironmentState[K]
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const getEnvironmentalValue = <
  K extends keyof EnvironmentState
>(
	key: K,
	env: EnvironmentState[K]['e'],
	state: Array<EnvironmentState[K]>,
) => {
	return findMatches(key, env, state)[0]
}

export const setEnvironmentalValue = <
  K extends keyof EnvironmentState
>(
	key: K,
	env: EnvironmentState[K]['e'],
	state: Array<EnvironmentState[K]>,
	value: EnvironmentState[K]['v'],
): void => {
	// Load matched value from init values
	const init = findMatches(key, env, state, { itemScope: 'init', exactOnly: true })

	// Load matched value from user values
	const user = findMatches(key, env, state, { itemScope: 'user', exactOnly: true })
	
	if (init[0]?.v === value) {						// If new value is same as init's value, then remove user value if exist
		user.sort((a, b) => b.index - a.index).forEach(item => {
			state.splice(item.index, 1)
		})
	} else if (user.length === 0) {				// If user hasn't prepared value before, then add a new item
		state.push(createRecord<K>(key, value, env))
	} else if (user[0].v !== value) {			// otherwise; update the existence item with the new value
		state[user[0].index].v = value
	}
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\


// interface Environment {
// 	// On initial
// 	type: 'web' | 'app';
// 	name: string;

// 	// Update from theme
// 	screen: string;

// 	// Auto detect on load, Changeable
// 	locale: string;
// 	userId: string;
// }

// LayoutSettings
//   locale
//   navbar
//   header

// const PageSettings = {
//   view: ['table', 'list', 'grid', 'tree'], // overridable, environmental
//   sorts: ['sort 1', 'sort 2'], // mergeable, not environmental
//   activeSort: 'sort 1',  // overridable, not environmental
//   filters: ['sort 1', 'sort 2'], // mergeable, not environmental
//   activeFilter: 'filter 1',  // overridable, not environmental
//   hiddenColumns: ['column 1', 'column 2'], // overridable, environmental
//   pageSize: 40, // overridable, environmental
// }

// const CompanyPageSettings = {
//   view: ['table', 'list', 'grid', 'tree'], // overridable, environmental
//   sorts: ['sort 1', 'sort 2'], // mergeable, not environmental
//   activeSort: 'sort 1',  // overridable, not environmental
//   filters: ['sort 1', 'sort 2'], // mergeable, not environmental
//   activeFilter: 'filter 1',  // overridable, not environmental
//   hiddenColumns: ['column 1', 'column 2'], // overridable, environmental
//   pageSize: 40, // overridable, environmental
// }

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

// The basic structure, includes all variables with it's own value type
export type EnvironmentStruc = {
	[K in keyof EnvironmentState]: EnvironmentState[K]['v']
} & {
	path: string;					// Base path to use as a key
	type: 'web' | 'app';	// Environment name \ type

	mediaX: Breakpoint
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

interface EnvironmentValue<T, Env extends keyof EnvironmentStruc> {
  v: T;										// Value
  e: {										// Requirnments
		[E in Env]: EnvironmentStruc[E] | '*'
	}
}

export interface EnvironmentState {
	// Basic settings
	app: EnvironmentValue<
		string,
		'path' | 'type'
	>;

	// Layout settings
	lng: EnvironmentValue<
		string,
		'path' | 'type' | 'app'
	>;

	sidebar: EnvironmentValue<
		'hide' | 'tiny' | 'wide',
		'path' | 'type' | 'mediaX'
	>;

	// Page settings
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

type MatchItem<K extends keyof EnvironmentState> = EnvironmentState[K] & {
	score: number;
	order: number;
	index: number;
}

interface MatchOptions {
	exactOnly?: boolean;
	itemScope?: 'user' | 'init'
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

type NonEmptyArray<T> = [T, ...T[]];

type EnvironmentSetup = {
  [K in keyof EnvironmentState]: {
		rank: { [key in keyof EnvironmentState[K]['e']]?: number } // Higher is better \ has more priority
		init: NonEmptyArray<EnvironmentState[K]>;
		enum?: Array<EnvironmentStruc[K]>;
	}
}