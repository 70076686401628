import { isEqual } from './isEqual';
import { isPlainObject } from './isObject';

export const discardDefaults: DiscardDefaults = (object, defaults) => {
	const changes: any = {};

	for (const key in object) {
		if (!Object.hasOwn(object as object, key))
			continue

		const _value = object[key];
		const _alter = isPlainObject(defaults) ? defaults[key] : undefined;

		if (isPlainObject(_alter) && isPlainObject(_value)) {
			changes[key] = discardDefaults(_value, _alter);

			if (Object.keys(changes[key]).length === 0)  {
				delete changes[key]
			}
		} else if (!isEqual(_value, _alter)) {
			changes[key] = _value
		}
	}

	return changes
}

type DiscardDefaultsResult<T, U> = T extends object
  ? {
      [K in keyof T]: K extends keyof U
        ? T[K] extends U[K]
          ? never
          : T[K]
        : T[K];
    }
  : T;

export type DiscardDefaults = <T, U>(object: T, defaults: U) => DiscardDefaultsResult<T, U>;
