import { IsEqual, IsEqualTo, isEqual } from './helpers';


Array.isEqual = isEqual

// eslint-disable-next-line no-extend-native
Array.prototype.isEqual = function (other, options) {
	return isEqual(this, other, options)
}

declare global {
  interface Array<T> {
    isEqual: IsEqualTo<Array<T>>;
  }

  interface ArrayConstructor {
    isEqual: IsEqual<Array<any>>
  }
}