import { EqualityFn } from 'react-redux';
import { Action, UnknownAction, combineReducers, configureStore } from '@reduxjs/toolkit';
// import treeTbReducer from 'containers/Tables/reducers/treeTbReducer';
// import crudTbReducer from 'containers/Tables/reducers/crudTbReducer';
// import crudTbFrmReducer from 'containers/Tables/reducers/crudTbFrmReducer';
// import ecommerceReducer from 'containers/SampleApps/Ecommerce/reducers/ecommerceReducer';
// import emailReducer from 'containers/SampleApps/Email/reducers/emailReducer';
// import timelineReducer from 'containers/SampleApps/Timeline/reducers/timelineReducer';
// import calendarReducer from 'containers/SampleApps/Calendar/reducers/calendarReducer';
// import taskboardReducer from 'containers/SampleApps/TaskBoard/reducers/taskboardReducer';
import { appSlice, /* authSlice, */ checkInAsync, envSlice, /* initFormReducer, uiSlice */ } from './slices';
import { LOAD_INITIAL_STATE, createPersistMiddleware } from './middleware';

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

const rootReducer = combineReducers({
  app: appSlice.reducer,
  env: envSlice.reducer,

  // ui: uiSlice.reducer,
  // auth: authSlice.reducer,
  
  // initval: initFormReducer,
  // // login: loginReducer,
  // treeTable: treeTbReducer,
  // treeTablePM: treeTbReducer,
  // treeTableArrow: treeTbReducer,
  // crudTable: crudTbReducer,
  // crudTableDemo: crudTbReducer,
  // crudTableForm: crudTbFrmReducer,
  // crudTbFrmDemo: crudTbFrmReducer,
  // ecommerce: ecommerceReducer,
  // email: emailReducer,
  // socmed: timelineReducer,
  // calendar: calendarReducer,
  // taskboard: taskboardReducer,

  // counter: counterSlice.reducer,
})

export const store = configureStore<RootState, any, any>({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
  .concat(
    createPersistMiddleware([
      {
        reducer: appSlice.name,
        ignore: ['error', 'status'],
        assignFn: appSlice.actions.assignAppValuesAction,
      },
      {
        reducer: envSlice.name,
        ignore: [],
        assignFn: envSlice.actions.assignEnvValuesAction,
      },
      // {
      //   reducer: uiSlice.name,
      //   ignore: ['alterScheme', 'expandMenus'],
      //   assignFn: uiSlice.actions.assignUIValuesAction
      // }
    ])
  ),
});


Promise.all([
  store.dispatch({ type: LOAD_INITIAL_STATE }),
  store.dispatch(checkInAsync()),
]).then(() => {
  window.dispatchEvent(new Event('load:store'));
})

// ** ----------------------------------------------------------------------------------------------------------- ** \\

export default store;

export * from './slices';
export * from './hooks';

export type RootState = ReturnType<typeof rootReducer>;

declare module 'redux' {
	interface Dispatch<A extends Action = UnknownAction> {
		<T = A>(action: T): T
	}
}

declare module 'react-redux' {
  interface UseSelectorOptions<Selected = RootState> {
    equalityFn?: EqualityFn<Selected>;
  }

  interface UseSelector {
    <Selected = unknown, TState = RootState>(selector: (state: TState) => Selected, equalityFn?: EqualityFn<Selected>): Selected;
    <Selected = unknown, TState = RootState>(selector: (state: TState) => Selected, options?: UseSelectorOptions<Selected>): Selected;
  }
}