
const createAssignable = <T extends Record<string, any>>(initialState: T, { cbAfter }: Options<T> = {}) => {

	return (state: T, payload: AssignablePayload<T>) => {

		const values = typeof payload === 'function' ? payload(state) : payload

		var key: keyof typeof values
		for(key in values) {

			if (!(key in initialState)) {
				continue;
			}
	
			state[key] = values[key] as any
		}

		if (typeof cbAfter === 'function') {
			cbAfter(state, payload);
		}
	};
}


export default createAssignable;

export type AssignablePayload<T> = Partial<T> | ((state: T) => Partial<T>)

interface Options<T> {
	cbAfter?: (state: T, payload: AssignablePayload<T>) => void
}