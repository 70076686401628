import merge from 'lodash/merge'
import isFunction from 'lodash/isFunction'

import { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { CssBaseline, ThemeOptions, ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';

import { useDirection } from '@Plugin/i18n';
import { deepTruncate } from './utils';
import { createThemeOptions } from './createThemeOptions';
// import { TemplateSettings } from 'dan-components';
import { makeStyles } from 'tss-react/mui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useThemeEnvironment } from '@Plugin/env';
// import { useRouteEnvironment } from '@Plugin/router/RouteEnvironment';

// import '@Asset/sass/theme.sass'


const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    minHeight: '100%',
    marginTop: 0,
    zIndex: 1,
    display: 'contents'
  },
}));


export const ThemeContext = createContext<ThemeContext>(
  {} as ThemeContext
);

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {

	const { classes } = useStyles();
	const { direction } = useDirection();
	const { i18n: { /* dir, */ language } } = useTranslation();

	const { type, app: appId } = useThemeEnvironment()

	// TODO: Loading dynamic options, from current user
	const userOptions: ThemeOptions = useMemo(() => ({}), [])

	const storeOptions = useSelector(({ app }) => (
		(((type === 'app' && appId) && app.platforms.find(platform => platform.id === appId)) || app).theme
	))

	const mixedOptions = useMemo(() => merge(
		{},
		createThemeOptions(direction, storeOptions),
		userOptions,
	), [userOptions, storeOptions, direction])

	// Dynamic options, from current session
	const [sessionOptions, setSessionOptions] = useState<ThemeOptions>({});

	const assignOptions: ThemeContext['assignOptions'] = (options) => {
		const newOptions = isFunction(options) ? options(merge({}, mixedOptions, sessionOptions)) : options

		setSessionOptions(deepTruncate(newOptions, sessionOptions, mixedOptions))
	}

	const muiTheme = useMemo(() => createTheme(
		merge(
			{},
			mixedOptions,
			sessionOptions
		)
	), [mixedOptions, sessionOptions]);

  // ** --------------------------------------------------------------------------------------------------------- ** \\
  // ** --------------------------------------------------------------------------------------------------------- ** \\

  return (
		<MuiThemeProvider theme={muiTheme}>
			{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
			<CssBaseline />

			<div className={classes.root}>

			{/* <TemplateSettings /> */}

      <ThemeContext.Provider value={{
        assignOptions,
      }}>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
					{children || (
						<Outlet />
					)}
				</LocalizationProvider>
      </ThemeContext.Provider>
			</div>
		</MuiThemeProvider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export const Component = ThemeProvider

export default useThemeContext;

// type SetOption = <TKey extends keyof ThemeOptions>(key: TKey, value?: ThemeOptions[TKey]) => void;

type UserThemeOptions = Omit<ThemeOptions, 'direction'>

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
interface ThemeContext {
  assignOptions: (options: UserThemeOptions | ((value: UserThemeOptions) => UserThemeOptions)) => void
}
