import './loaded.sass'

// eslint-disable-next-line import/no-webpack-loader-syntax
import '!file-loader?name=[name].[ext]!./loader.css';

let counter = 0;
const onLoad = () => {
	if (++counter < 3) {
		return;
	}

	window.removeEventListener('load', onLoad)
	window.removeEventListener('load:store', onLoad)
	window.removeEventListener('load:checkin', onLoad)

	document.documentElement.classList.add('loaded')

	setTimeout(() => {
		document.querySelector('html > body > #loader')?.remove();
		document.documentElement.classList.remove('loading', 'loaded')
	
		for (const idx in document.styleSheets) {
			const css = document.styleSheets[idx];
			if (css.title === 'loader') {
				css.disabled = true;
			}
		}
	}, 500)
}

window.addEventListener('load', onLoad)
window.addEventListener('load:store', onLoad)
window.addEventListener('load:checkin', onLoad)