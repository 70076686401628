import i18n from './i18n'
import { Language, getFallbackLng } from './languages'

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const isAllOfIn = (allOf: typeof i18n.options.fallbackLng, mustIn: string[] | readonly string[]) => {
	const fn = (check: typeof i18n.options.fallbackLng): boolean => {
		if (check) {
			if (typeof check === 'string') {
				return mustIn.includes(check)
			} else if (Array.isArray(check)) {
				return check.every(fn)
			} else if (typeof check === 'function') {
				for (const x of mustIn) {
					if (!fn(check(x))) {
						return false
					}
				}
			} else if (Object.isPlainObject(check)) {
				for (const x of mustIn) {
					const _val = (check as any)[x];
					if (_val && !fn(_val)) {
						return false
					}
				}
			}
		}

		return true;
	}
	
	return fn(allOf);
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const updateLocale = (type: string, lngs: Array<Language['code']>, lng?: string) => {

	const defaultNS = type === 'web' ? 'web' : 'page'
	const fallbackLng = getFallbackLng(lngs)

	// Check supported languages, and Check fallback language
	if (isAllOfIn(lngs, i18n.options.supportedLngs || [])) {
		i18n.options.fallbackLng = fallbackLng

		// Check the default namespace
		if (i18n.options.defaultNS !== defaultNS) {
			i18n.setDefaultNamespace(defaultNS)
			i18n.options.ns = defaultNS
		}

		// Check the current language
		if (lng && i18n.language !== lng) {
			i18n.changeLanguage(lng)
		}
	} else {
		i18n.init({
			lng,
			ns: defaultNS,
			defaultNS,
			fallbackLng,
			supportedLngs: Array.from(new Set([lngs, i18n.options.supportedLngs || []].flat())),
			resources: i18n.store.data,
		})
	}
}