import merge from 'lodash/merge'
import { decrypt, encrypt, discardDefaults } from './helpers';

Storage.prototype.setItem = new Proxy(Storage.prototype.setItem, {
	apply(target, thisArg, argumentList) {
		const [key, newValue] = argumentList
		const event = new CustomEvent('storage', {
			detail: { key, newValue, oldValue: thisArg.getItem(key) },
		});
		
		window.dispatchEvent(event);
		return Reflect.apply(target, thisArg, argumentList);
	},
});

Storage.prototype.removeItem = new Proxy(Storage.prototype.removeItem, {
	apply(target, thisArg, argumentList) {
			const [key] = argumentList[0]
			const event = new CustomEvent('storage', {
					detail: { key, },
			});
			window.dispatchEvent(event);
			return Reflect.apply(target, thisArg, argumentList);
	},
});

Storage.prototype.clear = new Proxy(Storage.prototype.clear, {
	apply(target, thisArg, argumentList) {
			const event = new CustomEvent('storage', {
					detail: {
							key: '__all__',
					},
			});
			window.dispatchEvent(event);
			return Reflect.apply(target, thisArg, argumentList);
	},
});


Storage.prototype.getJson = async function (key, options) {
  let str = this.getItem(key)

  if (str) {
		if (options?.crypt) {
			str = (await decrypt(str)) || '';
		}
	}

	return merge(
		{},
		(options?.merge ? options?.default : {}),
		JSON.forceParse(
			str,
			(options?.merge ? {} : options?.default)
		)
	)
}

Storage.prototype.setJson = async function (key, value, options) {
	let obj = typeof value === 'object' && value;

	if (options?.trim === true) {
		obj = discardDefaults(obj, options.default)
	}

	if (!obj || Object.keys(obj).length === 0) {
		this.removeItem(key)
	} else {
		let str = JSON.stringify(obj);

		if (options?.crypt) {
			str = await encrypt(str) || ''
		}

		this.setItem(key, str);
	}
}

interface Options {
	crypt?: boolean
}

type GetJsonOptions<T> = ({
	merge?: boolean;
	default?: T;
}) | ({
	merge: true;
	default: T;
})

type SetJsonOptions<T> = ({
	trim: true;
	default: T;
}) | ({
	trim?: false;
})

declare global {
  interface Storage {
    getJson<T extends object = object>(key: string, options?: Options & GetJsonOptions<T>): Promise<T | undefined>;
		setJson<T extends object = object>(key: string, value: DeepPartial<T>, options?: Options & SetJsonOptions<T>): Promise<void>;
  }
}