
export const isEmpty = (obj: object) => {
	if (Array.isArray(obj))
		return obj.length === 0

	for (const key in obj) {
		if (Object.hasOwn(obj, key))
			return false
	}

	return true;
}