import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import {
  FolderOffTwoTone,
  HighlightOffTwoTone,
  LockTwoTone,
  MoodBadTwoTone,
  RefreshTwoTone,
  SentimentDissatisfiedTwoTone,
  SentimentVeryDissatisfiedTwoTone,
  WifiOffTwoTone
} from '@mui/icons-material';

import { ClientError } from '@Plugin/client';
import { checkInAsync, resetAppValuesAction } from '@Plugin/store';

import './PreError.sass'

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const ICON_MAP = {
	error400: HighlightOffTwoTone,
	error401: LockTwoTone,
	error403: FolderOffTwoTone,
	error404: SentimentDissatisfiedTwoTone,
	error500: SentimentVeryDissatisfiedTwoTone,
	errorConnection: WifiOffTwoTone,
	errorUnexpected: MoodBadTwoTone,
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

let cssStyle = ''
const styles = document.querySelectorAll('head > style');
for (var i = styles.length - 1; i >= 0; i--) {
  if (styles[i].textContent?.includes('pre-progress')) {
    cssStyle = styles[i].textContent ?? '';
    styles[i].remove()
    break;
  }
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

const PreError = memo<PreErrorProps>(({ error, isLoading }) => {

  const { t } = useTranslation(['label']);
  const [, setCounter] = useState(1)

  // ** --------------------------------------------------------------------------------------------------------- ** \\
  
  const dispatch = useDispatch()
  const retryHandler = useCallback(() => {
    // Mark status flag as [loading]
    dispatch(resetAppValuesAction())

    setCounter(current => {
      setTimeout(() => dispatch(checkInAsync()), current * 1000)
      return current + 0.5
    })
  }, [dispatch]);

  // ** --------------------------------------------------------------------------------------------------------- ** \\

  const { Icon, title, body1, body2 } = useMemo(() => {
    let errorKey: keyof typeof ICON_MAP;
    const status = error.response?.status ?? error.status ?? error.code;

    switch (status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 500:
        errorKey = `error${status}`
        break;
      case 'ERR_NETWORK':
        errorKey = 'errorConnection';
        break;
      default:
        errorKey = 'errorUnexpected';
    }

    return {
      Icon: ICON_MAP[errorKey],
      title: `api.${errorKey}.title`,
      body1: `api.${errorKey}.body1`,
      body2: `api.${errorKey}.body2`,
    }
  }, [error]);

  // ** --------------------------------------------------------------------------------------------------------- ** \\
  // ** --------------------------------------------------------------------------------------------------------- ** \\

  return (
    <div className={`pre-error ${isLoading ? 'loading' : 'content'}`}>
      <Helmet>
        <style>{cssStyle}</style>
      </Helmet>

      <div className="pre-error-loading">
        <span className="progress" />
      </div>

      <div className="pre-error-content">
        <Icon className="icon" />

        <p className="content">
          <h1>{t(title)}</h1>
          <b>{t(body1)}</b>
          <br />
          <i>{t(body2)}</i>

          <hr />

          <div style={{
            textAlign: 'end'
          }}>
            <button className="button" onClick={retryHandler}>
              <RefreshTwoTone />
              <span />
              {t('global.reload')}
            </button>
          </div>
        </p>
      </div>
    </div>
  );
});


export default PreError;

interface PreErrorProps {
  error: ClientError;
  isLoading: boolean;
}