import { ComponentProps, createElement, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { createThemeEnvironment } from '@Plugin/env';
import { ThemeProvider } from '@Plugin/theme';

import { RoutesBuilder } from './utils';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const DemoPage = lazy(() => import('@Template/Test/DemoPage').then(x => ({ default: x.Component })));

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const buildDemoRoutes: RoutesBuilder = (instance) => {

	// ** ---- Start: Append app routes for each platform --------------------------------------------------------- ** \\
	const items = instance.platforms.reduce((previous, current) => {
		if (current.path) {
			const path = `/demo${current.path}`;
			const pre = previous.get(path);
			if (pre) {
				pre.apps.push(current.id)
			} else {
				previous.set(path, {
					path,
					apps: [current.id],
					type: 'app',
					name: `${current.name ?? current.path} Routes`,
					Component: ThemeProvider,
				})
			}
		}
		
		return previous;
	}, new Map<string, Env>())
	// ** ---- Final: Append app routes for each platform --------------------------------------------------------- ** \\

	// ** --------------------------------------------------------------------------------------------------------- ** \\

	// ** ---- Start: Append web routes for the instance ---------------------------------------------------------- ** \\
	const path = `/demo${instance.route?.home ?? '/'}`;
	items.set(path, {
		path,
		apps: [],
		type: 'web',
		name: `Web Routes`
	})
	// ** ---- Final: Append app routes for the instance ---------------------------------------------------------- ** \\

	// ** --------------------------------------------------------------------------------------------------------- ** \\

	const groups = [...items.entries()].map(([_, { path, name }]) => ({ path, name }))

	return [...items.entries()].map(([_, { name, ...env }]) => ({
		path: env.path,
		Component: createThemeEnvironment(env),
		children: [
			{ element: createElement(Navigate, { to: 'demo1', replace: true }), index: true },
			{ path: 'demo1', element: createElement(DemoPage, { groups }) },
			{ path: 'demo2', element: createElement(DemoPage, { groups }) },
			{ path: 'demo3', element: createElement(DemoPage, { groups }) },
			{ path: 'demo4', element: createElement(DemoPage, { groups }) },
			{ path: 'demo5', element: createElement(DemoPage, { groups }) },
		]
	})).concat({
		path: '/',
		Component: createThemeEnvironment({
			path: '/',
			type: 'app',
			apps: instance.platforms.map(x => x.id),
			Component: ThemeProvider,
		}),
		children: [
			{
				path: '',
				lazy: () => import('@Template/Page/ComingSoon'),
			},
			{
				path: '/*',
				lazy: () => import('@Template/Error/404'),
			}
		] as any
	})
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

type Env = Parameters<typeof createThemeEnvironment>[0] & Omit<ComponentProps<typeof DemoPage>, 'children'>['groups'][0]