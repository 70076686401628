import { ThemePalette } from '.';

export const darkPalette: ThemePalette = {
  blue: {
    primary: {
      light: '232,234,246',
      main: '68,138,255',
      dark: '40,53,147',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '225,245,254',
      main: '3,169,244',
      dark: '2,119,189',
      contrastText: '255,255,255'
    }
  },
  cyan: {
    primary: {
      light: '224,247,250',
      main: '38,166,154',
      dark: '0,105,92',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '241,248,233',
      main: '104,159,56',
      dark: '51,105,30',
      contrastText: '255,255,255'
    }
  },
  blueCyan: {
    primary: {
      light: '224,247,250',
      main: '0,188,212',
      dark: '0,131,143',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '225,245,254',
      main: '3,155,229',
      dark: '1,87,155',
      contrastText: '255,255,255'
    }
  },
  gold: {
    primary: {
      light: '255,249,196',
      main: '255,145,0',
      dark: '255,109,0',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '239,235,233',
      main: '161,136,127',
      dark: '78,52,46',
      contrastText: '255,255,255'
    }
  },
  greenOrange: {
    primary: {
      light: '241,248,233',
      main: '104,159,56',
      dark: '51,105,30',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '255,248,225',
      main: '255,143,0',
      dark: '230,81,0',
      contrastText: '255,255,255'
    }
  },
  greenPurple: {
    primary: {
      light: '237,231,246',
      main: '179,136,255',
      dark: '49,27,146',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '232,245,233',
      main: '0,200,83',
      dark: '27,94,32',
      contrastText: '255,255,255'
    }
  },
  grey: {
    primary: {
      light: '236,239,241',
      main: '144,164,174',
      dark: '69,90,100',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '224,224,224',
      main: '117,117,117',
      dark: '66,66,66',
      contrastText: '255,255,255'
    }
  },
  magenta: {
    primary: {
      light: '252,228,236',
      main: '240,98,146',
      dark: '216,27,96',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '255,248,225',
      main: '255,160,0',
      dark: '255,111,0',
      contrastText: '255,255,255'
    }
  },
  orange: {
    primary: {
      light: '255,243,224',
      main: '239,108,0',
      dark: '230,81,0',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '243,229,245',
      main: '186,104,200',
      dark: '123,31,162',
      contrastText: '255,255,255'
    }
  },
  pinkBlue: {
    primary: {
      light: '224,247,250',
      main: '0,188,212',
      dark: '0,96,100',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '252,228,236',
      main: '240,98,146',
      dark: '173,20,87',
      contrastText: '255,255,255'
    }
  },
  pinkGreen: {
    primary: {
      light: '220,237,200',
      main: '104,159,56',
      dark: '51,105,30',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '252,228,236',
      main: '240,98,146',
      dark: '173,20,87',
      contrastText: '255,255,255'
    }
  },
  purple: {
    primary: {
      light: '237,231,246',
      main: '186,104,200',
      dark: '142,36,170',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '224,247,250',
      main: '0,188,212',
      dark: '0,96,100',
      contrastText: '255,255,255'
    }
  },
  purpleRed: {
    primary: {
      light: '237,231,246',
      main: '179,136,255',
      dark: '81,45,168',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '252,228,236',
      main: '236,64,122',
      dark: '194,24,91',
      contrastText: '255,255,255'
    }
  },
  red: {
    primary: {
      light: '255,235,238',
      main: '239,83,80',
      dark: '229,57,53',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '236,239,241',
      main: '96,125,139',
      dark: '69,90,100',
      contrastText: '255,255,255'
    }
  },
  skyBlue: {
    primary: {
      light: '227,242,253',
      main: '66,165,245',
      dark: '21,101,192',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '224,242,241',
      main: '0,191,165',
      dark: '0,121,107',
      contrastText: '255,255,255'
    }
  },
  yellowBlue: {
    primary: {
      light: '225,245,254',
      main: '3,155,229',
      dark: '1,87,155',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '255,243,224',
      main: '255,152,0',
      dark: '230,81,0',
      contrastText: '255,255,255'
    }
  },
  yellowCyan: {
    primary: {
      light: '255,243,224',
      main: '249,168,37',
      dark: '245,127,23',
      contrastText: '255,255,255'
    },
    secondary: {
      light: '224,247,250',
      main: '0,188,212',
      dark: '0,96,100',
      contrastText: '255,255,255'
    }
  }
}

export default darkPalette;
