import { PropsWithChildren, memo } from 'react'
import { axios } from './axios';
import { SWRConfig } from 'swr';
import { apiStorage } from './utils';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const createFetcher = (...headers: Array<string>) => (url: string) => axios.get(url).then((response) => {
	headers.forEach(name => 
		apiStorage.setHeader(url, name, response.headers[`x-${name}`])
	)

	return response.data
})

const fetcher = createFetcher('page', 'size', 'data')

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const ConfiguredSWRProvider = memo<PropsWithChildren>(({ children }) => (
	<SWRConfig value={{
		shouldRetryOnError: true,
		revalidateIfStale: false,
		revalidateOnFocus: false,
		// revalidateOnMount: false,
		revalidateOnReconnect: false,
		suspense: false,
		refreshInterval: 0,
		fetcher
	}}>
		{children}
	</SWRConfig>
))


export default ConfiguredSWRProvider;
