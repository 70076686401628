import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import createAssignable, { AssignablePayload } from '../utils/createAssignable';
import { KnownIconName } from '@Component/MuiIcon';
import { axios, ClientError } from '@Plugin/client';

import { UIStyle } from '@Plugin/theme/palette';
import i18n, { Language, supportedLanguages } from '@Plugin/i18n';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const name = 'app'

const demoInstance: Instance = {
  id: '123',

  host: 'app.mo-a.me',
  icon: undefined,
  name: undefined,

  title: 'Demo',
  brief: {
    'en-US': 'Demo version',
    'ar-EG': 'نسخة تجريبية',
  },
  langs: ['en-US', 'ar-EG'],
  media: {
    Email: 'moa@mo-a.me',
    Phone: 'tel:+201111667339',
    WhatsApp: '+201111667339',
    GitHub: 'Mo-Awadly',
    LinkedIn: 'Mo-Awadly'
  },

  route: {
    home: '/',
    blog: '/blog',
    login: '/login',
    reset: '/reset',
    register: '/signup',
    articles: '/blog/:id/',
    products: '/product',
  },

  theme: {
    paletteName: 'gold',
  },
  platforms: []
}

const initialState: AppState = {

  ...demoInstance,

  // appLang: '',
  // webLang: '',
  status: 'idle',
};

const assignValues = createAssignable(initialState, {
  cbAfter: (state) => {
    // Create localizable resouces
    const builder = buildI18nResources(state.langs ?? [])

    builder('name', state.name)
    builder('title', state.title)
    builder('brief', state.brief)

    state.langs = builder.languages

    let lng: Language['code'];
    for (lng in builder.resources) {
      i18n.addResourceBundle(lng, 'web', builder.resources[lng])
    }
  }
})

const resetAppValues = (state: AppState, payload?: { data?: Instance, error?: ClientError }) => {
  const { data, error } = payload ?? {};

  if (error) {
    state.error = error;
    state.status = 'failed';
  } else if (data) {
    // Assign instance props
    assignValues(state, data)

    // Update status
    state.error = undefined;
    state.status = 'succeeded';
  } else {
    state.status = 'loading';
  }
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const checkInAsync = createAsyncThunk(
  `${name}/checkIn`,
  async (_, { fulfillWithValue, rejectWithValue }) => new Promise<Instance>((resolve) => resolve(demoInstance)).finally(() => {
    window.dispatchEvent(new Event('load:checkin'));
  }) || axios.get<Instance>(`/app/check-in`).then(data => {

    if (!data.data || typeof data.data !== 'object' || !data.data.id) {
      return rejectWithValue(data.data)
    }

    return fulfillWithValue(data.data)
  }).catch((error) => {
    return rejectWithValue(error)
  }).finally(() => {
    window.dispatchEvent(new Event('load:checkin'));
  }),
);

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const localeMap = supportedLanguages.reduce<Record<string, Language['code']>>((prev, curr) => Object.assign(prev, {
  [curr.code.split('-')[0]]: curr.code,
}), {})

const buildI18nResources = (langs: Array<Language['code']>, res?: I18nRes) => {

  const fn = (key: string, data?: I18nValue) => {
    if (data && typeof data === 'object') {
      for (const lng in data) {
        const code = localeMap[lng] ?? supportedLanguages.find(({ code }) => code === lng);

        if (code in fn.resources) {
          fn.resources[code][key] = data[lng as Language['code']]
        }
      }
    } else {
      for (const lng of fn.languages) {
        fn.resources[lng][key] = data
      }
    }

    return fn;
  }

  // Generate important properties
  fn.resources = res ?? {} as I18nRes
  fn.languages = langs.map(lng => localeMap[lng] ?? lng)

  // Initial the resources object
  for (const lng of fn.languages) {
    if (!fn.resources[lng]) {
      fn.resources[lng] = {}
    }
  }

  return fn
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const appSlice = createSlice({
  name,
  initialState,
  reducers: {
		assignAppValuesAction: (state, action: PayloadAction<AssignablePayload<AppState>>) => assignValues(state, action.payload),
    resetAppValuesAction: (state, action: PayloadAction<Parameters<typeof resetAppValues>[1]>) => resetAppValues(state, action.payload),
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkInAsync.pending, (state) => resetAppValues(state))
      .addCase(checkInAsync.fulfilled, (state, action) => resetAppValues(state, {
        data: action.payload
      }))
      .addCase(checkInAsync.rejected, (state, action) => resetAppValues(state, {
        error: action.payload as ClientError
      }));
  },
})

// Action creators are generated for each case reducer function
export const { assignAppValuesAction, resetAppValuesAction } = appSlice.actions

export default appSlice

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\


export interface Instance {
	id: string;

	host?: string;
  lang?: string;
	icon?: string;
  name?: I18nValue;

  title?: I18nValue;
  brief?: I18nValue;
  langs?: Array<Language['code']>;

  route?: {
    home?: string,
    blog?: string,
    login?: string,
    reset?: string,
    register?: string,
    articles?: string,
    products?: string,
  };
  media?: {
    [key in KnownIconName]?: string;
  }

  theme: Partial<UIStyle>,
 

  platforms: Array<Platform>;
  // 	meta?: Record<string, string>;

	// image?: string;
	// cover?: string;
}

interface Platform {
  id: string;

  path: string;
  name: string;
  logo: string;

  langs: Array<Language['code']>;
  theme: Partial<UIStyle>;

  settings: object;
}

// export interface AppPlatform extends PlatformBase {
	
// }

// export interface WebPlatform extends PlatformBase {
	
// }

// interface Website {
	



	// call?: Record<KnownIconName, string>;

// 	currentState?: 'disabled' | 'running' | 'commingSoon' | 'maintenance' | 'underConstruction';
// 	homeTemplate?: 'corporate' | 'creative' | 'none';
// 	homeSections?: Array<WebsiteSection>
// }



// {
//   "siteSettings": {
//     "title": "My Awesome Web App",
//     "icon": "https://example.com/icon.png",
//     "fallbackLanguage": "en",
//     "supportedLanguages": ["en", "es", "fr", "de"],
//     "contactInfo": {
//       "email": "support@example.com",
//       "phone": "+1234567890",
//       "address": "123 Web App Street, Internet City"
//     }
//   },
//   "uiOptions": {
//     "theme": "dark",
//     "themesAvailable": ["light", "dark", "system"],
//     "layout": "grid",
//     "showTooltips": true,
//     "enableAnimations": true,
//     "dateFormat": "YYYY-MM-DD",
//     "timeFormat": "HH:mm:ss"
//   },
//   "userPreferences": {
//     "language": "en",
//     "notifications": {
//       "email": true,
//       "sms": false,
//       "push": true
//     },
//     "privacySettings": {
//       "tracking": false,
//       "ads": false
//     }
//   },
//   "socialMediaLinks": {
//     "facebook": "https://facebook.com/myawesomeapp",
//     "twitter": "https://twitter.com/myawesomeapp",
//     "instagram": "https://instagram.com/myawesomeapp",
//     "linkedin": "https://linkedin.com/company/myawesomeapp"
//   },
//   "features": {
//     "chatSupport": true,
//     "liveUpdates": true,
//     "offlineMode": false,
//     "multiFactorAuthentication": true,
//     "backupFrequency": "daily"
//   },
//   "apiEndpoints": {
//     "getUserData": "https://api.example.com/user/data",
//     "postUserFeedback": "https://api.example.com/user/feedback",
//     "fetchNotifications": "https://api.example.com/notifications"
//   },
//   "branding": {
//     "logo": "https://example.com/logo.png",
//     "logoDark": "https://example.com/logo-dark.png",
//     "primaryColor": "#3498db",
//     "secondaryColor": "#2ecc71",
//     "fontFamily": "Arial, sans-serif"
//   },
//   "errorMessages": {
//     "404": "Page not found",
//     "500": "Internal server error",
//     "networkError": "Network error, please try again later"
//   },
//   "miscSettings": {
//     "autoSaveInterval": 300,
//     "maxUploadSizeMB": 50,
//     "allowedFileTypes": ["jpg", "png", "pdf", "docx"],
//     "maintenanceMode": false
//   }
// }


// website
// platform
// instance



// interface WebsiteSection {
// 	id: string;
// 	type: 'banner' | 'contact'
// 	platformId?: string;
// }



interface AppState extends Instance {
  
  error?: ClientError;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';

  // appLang: string;
  // webLang: string;

  // platforms: [];
}


type I18nRes<T = any, K extends string = Language['code']> = {
	[k in K]?: T
}
type I18nValue<T = string> = T | I18nRes<T>