import { isPlainObject } from './isObject';
import { getTypeName } from './getTypeName';
import { createParser } from './parser';
import { toDate } from './toDate';
import { toNumber } from './toNumber';
import { isEmpty } from './isEmpty';


const parser = createParser();

const isEqualOptions = {
	looseNull: false,
	looseEmpty: false,
	nullIsEmpty: false,

	looseDate: false,
	looseBool: false,
	looseNumber: false,
	looseString: false,
	handleJsons: false,
}

const analyzeValue = (value: any, options: IsEqualOptions) => {

	// Convert to a standardized value
	let oValue: any = false
	let loosed: boolean = false
	let isNull: boolean = (value === undefined || value === null)
	let bEmpty: boolean = false

	// Wrap value into Object() constructor
	// - 12345 => Object(12345)
	// - 'str' => Object('str')
	const object = Object(value);

	// Detect typename of object, [Date, Number, String, Object, ...]
	const type = getTypeName(value);
	const safe = value && (
		typeof object.valueOf === 'function'
		? object.valueOf()
		: Object.prototype.valueOf.apply(object)
	)

	if (isNull) {
		loosed = options.looseNull
		oValue = (loosed) && null

	} else if (type === 'Date') {
		loosed = options.looseDate
		oValue = (loosed) && safe

	} else if (type === 'Number') {
		loosed = options.looseNumber || isNaN (safe)
		oValue = (loosed) && String(parseFloat(safe))

	} else if (type === 'Boolean') {
		loosed = options.looseBool
		oValue = (loosed) && String(safe)

	} else if (type === 'String') {
		bEmpty = (options.looseEmpty ? String(safe).trim() : String(safe)).length === 0
		loosed = bEmpty ? options.looseEmpty : options.looseString
		oValue = (loosed) && String(safe).trim()

	} else if ( type === 'Error' ) {
		loosed = options.handleJsons
		oValue = {
			name: safe.name,
			message: safe.message,
			...safe
		}

	} else if (type === 'Object' || type === 'Array') {
		bEmpty = isEmpty(safe)
		loosed = bEmpty ? options.looseEmpty : options.handleJsons

	}

	return {
		type: type === 'Object' && isPlainObject(safe) ? 'PlainObject' : type,
		value: oValue === false ? safe : oValue,
		loosed,
		isNull,
		isEmpty: bEmpty,
	}
}


const eq = (value1: any, value2: any, options: IsEqualOptions, stack?: WeakMap<object, any>): boolean => {

	const v1 = analyzeValue(value1, options);
	const v2 = analyzeValue(value2, options);
	const sameType = v1.type === v2.type;

	// Primitive cases
	if (v1.value === v2.value)																				// Values are same
		return Boolean(
			(sameType) || (v1.loosed || v2.loosed)
		)
	else if ((v1.isNull || v1.isEmpty) || (v2.isNull || v2.isEmpty))	// Any value is null or empty
		return Boolean(
			((sameType || options.looseNull) && v1.isNull && v2.isNull) ||
			((sameType || options.looseEmpty) && v1.isEmpty && v2.isEmpty) ||
			(options.nullIsEmpty && (v1.isNull || v1.isEmpty) && (v2.isNull || v2.isEmpty))
		)
	else if (!(sameType) && !(v1.loosed || v2.loosed))
		return false

	// ** --------------------------------------------------------------------------------------------------------- ** \\
	// ** --------------------------------------------------------------------------------------------------------- ** \\

	const types = [ v1.type, v2.type ]
	let _values = [ v1.value, v2.value ]

	if ( types.includes('Date') )	{																// Check for date/time values
		if (isNaN(_values[0]) && isNaN(_values[1]))
			return true
		else if ( !options.looseDate )
			return false

		_values = _values.map(v => toDate(v).valueOf())					// Ensure that all values are date/time

	} else if ( types.includes('Boolean') )	{											// Check for true/false values
		if ( !options.looseBool )
			return false

		_values = _values.map(v => {																// Ensure that all values are numbers
			switch (String(v).trim().toLowerCase()) {
				case '1':
				case 'on':
				case 'true':
					return 1
				case '0':
				case 'off':
				case 'false':
					return 0
			}

			return Math.abs(v)
		})

	} else if ( types.every(x => x === 'String') ) {							// Check for string values
		if ( !options.looseString )
			return false

		_values = _values.map(																			// Unify strings' case
			v => String(String(v).trim().toLowerCase())
		)

	} else if ( types.includes('Number') ) {											// Check for numeric values
		if ( !options.looseNumber )
			return false

		_values = _values.map(																			// Convert values to strings
			v => String(toNumber(v, v))
		)

	} else if (v1.type === 'Promise' || v1.type.includes('Element')) {
		return v1.value === v2.value

	} else if ( types.includes('RegExp') || types.includes('Function') ) {
		_values = _values.map(String)																// Convert values to strings

	} else if (_values.findIndex(v => typeof v === 'object' ) > -1 ) {
		// Nested comparative

		// Ensure that all values are arrays\objects
		_values = _values.map((v, i) => (types[i] === 'String' && options.handleJsons) ? parser(v.trim(), false) : v)

		if (!stack) {
			stack = new WeakMap();
		}

		const v1Stacked = stack.get(_values[0]), v2Stacked = stack.get(_values[1]);
		if (  v1Stacked && v2Stacked  ) {
			return v1Stacked === _values[1] && v2Stacked === _values[0];
		}

		stack.set(_values[0], _values[1]);
		stack.set(_values[1], _values[0]);


		v1.type = _values[0].constructor.name || getTypeName(_values[0])
		v2.type = _values[1].constructor.name || getTypeName(_values[1])

		// Ensure that all values are same type
		if (v1.type !== v2.type)
			return false;
		else if (v1.type === 'Array' && (_values[0].length !== _values[1].length))
			return false;

		let result: boolean = false;

		if (v1.type === 'Array') {
			result = Math.max(
				_values[0].length,
				_values[1].length
			) < 1

			if (!result)
				result = Array.prototype.every.apply(_values[0], [
					(v, index) => eq(v, _values[1][index], options, stack)
				])

		} else if (v1.type === 'Set') {
			result = !(
				(_values[0].size !== _values[1].size) ||
				(_values[0].size !== new Set(
					[
						Array.from( Set.prototype.keys.apply(_values[0]) ),
						Array.from( Set.prototype.keys.apply(_values[1]) ),
					].flat()
				).size)
			)

		} else if (v1.type === 'Map') {
			const keys = new Set(
				[
					Array.from( Map.prototype.keys.apply(_values[0]) ),
					Array.from( Map.prototype.keys.apply(_values[1]) ),
				].flat()
			)

			result = (keys.size < 1) || Array.from(keys).findIndex(
				(key: any) => !eq(
					Map.prototype.get.apply(_values[0], [key]),
					Map.prototype.get.apply(_values[1], [key]),
					options,
					stack
				)
			) === -1

		} else {
			const keys  = new Set(
				[
					Object.keys(_values[0]), Object.getOwnPropertySymbols(_values[0]), // Object.getOwnPropertyNames(_values[0]),
					Object.keys(_values[1]), Object.getOwnPropertySymbols(_values[1]), // Object.getOwnPropertyNames(_values[1]),
				].flat()
			)

			result = (keys.size < 1) || Array.from(keys).findIndex(
				(key: any) => !eq(_values[0][key], _values[1][key], options, stack)
			) === -1
		}

		stack.delete(_values[0])
		stack.delete(_values[1])

		return result;
	}

	// Compare primitive values
	return (_values[0] === _values[1])
}

export const isEqual: IsEqual = (value1, value2, loose) => {

	const isLoose = isPlainObject(loose) ? false : Boolean(loose)
	const options = isPlainObject(loose) ? loose : {}

	var k: keyof IsEqualOptions;
	for (k in isEqualOptions) {
		options[k] = Boolean(options[k] ?? options.handleJsons ?? (isLoose || isEqualOptions[k]))
	}

	return eq( value1, value2, options as IsEqualOptions )
}

export type IsEqualOptions = typeof isEqualOptions;

export type IsEqual<T = any, U = T> = (value1: T, value2: U, options?: boolean | Partial<IsEqualOptions>) => boolean;
export type IsEqualTo<T = any> = (other: T, options?: boolean | Partial<IsEqualOptions>) => boolean;