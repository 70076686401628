import { ThemeProvider } from '@Plugin/theme';
import { RoutesBuilder } from './utils';

// TODO: Check this variable porpues
// @ts-ignore
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

export const buildTemplateRoutes: RoutesBuilder = (instance) => [
	{
		path: '',
		Component: ThemeProvider,
		children: [
			// {
			// 	path: '/',
			// 	lazy: () => import('@Template/Landing'),
			// },
			// {
			// 	path: '/landing',
			// 	lazy: () => import('containers/App/Landing').then(c => ({ Component: c.default })),
			// },
			// {
			// 	path: '/landing-creative',
			// 	lazy: () => import('containers/App/LandingCreative').then(c => ({ Component: c.default })),
			// },
			{
				path: 'app',
				// lazy: () => import('containers/Templates/Dashboard').then(c => ({ Component: c.default })),
				children: [
					// // Home
					// {
					// 	path: '',
					// 	lazy: () => import('containers/Dashboard/PersonalDashboard').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'dashboard/sales-marketing',
					// 	lazy: () => import('containers/Dashboard/CrmDashboard').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'dashboard/cryptocurrency',
					// 	lazy: () => import('containers/Dashboard/CryptoDashboard').then(c => ({ Component: c.default })),
					// },

					// // Widgets
					// {
					// 	path: 'widgets/infographics',
					// 	lazy: () => import('containers/Widgets/Infographics').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'widgets/status',
					// 	lazy: () => import('containers/Widgets/Status').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'widgets/mini-apps',
					// 	lazy: () => import('containers/Widgets/MiniApps').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'widgets/analytics',
					// 	lazy: () => import('containers/Widgets/Analytics').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'widgets/info-updates',
					// 	lazy: () => import('containers/Widgets/InfoUpdates').then(c => ({ Component: c.default })),
					// },

					// // Layout
					// {
					// 	path: 'layouts',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'layouts/grid',
					// 	lazy: () => import('containers/Layouts/Grid').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'layouts/app-layout',
					// 	lazy: () => import('containers/Layouts/AppLayout').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'layouts/responsive',
					// 	lazy: () => import('containers/Layouts/Responsive').then(c => ({ Component: c.default })),
					// },

					// // Table
					// {
					// 	path: 'tables',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'tables/basic-table',
					// 	lazy: () => import('containers/Tables/BasicTable').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'tables/data-table',
					// 	lazy: () => import('containers/Tables/AdvancedTable').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'tables/table-playground',
					// 	lazy: () => import('containers/Tables/TablePlayground').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'tables/tree-table',
					// 	lazy: () => import('containers/Tables/TreeTable').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'tables/editable-cell',
					// 	lazy: () => import('containers/Tables/EditableCell').then(c => ({ Component: c.default })),
					// },

					// // Form & Button
					// {
					// 	path: 'forms',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/reduxform',
					// 	lazy: () => import('containers/Forms/ReduxForm').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/date-time-picker',
					// 	lazy: () => import('containers/Forms/DateTimePicker').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/checkbox-radio',
					// 	lazy: () => import('containers/Forms/CheckboxRadio').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/switches',
					// 	lazy: () => import('containers/Forms/Switches').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/selectbox',
					// 	lazy: () => import('containers/Forms/Selectbox').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/ratting',
					// 	lazy: () => import('containers/Forms/Rating').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/slider-range',
					// 	lazy: () => import('containers/Forms/SliderRange').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/buttons',
					// 	lazy: () => import('containers/Forms/Buttons').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/toggle-button',
					// 	lazy: () => import('containers/Forms/ToggleButton').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/dial-button',
					// 	lazy: () => import('containers/Forms/DialButton').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/textfields',
					// 	lazy: () => import('containers/Forms/Textbox').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/autocomplete',
					// 	lazy: () => import('containers/Forms/Autocomplete').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/upload',
					// 	lazy: () => import('containers/Forms/Upload').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'forms/wysiwyg-editor',
					// 	lazy: () => import('containers/Forms/TextEditor').then(c => ({ Component: c.default })),
					// },

					// // Ui Components
					// {
					// 	path: 'ui',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/avatars',
					// 	lazy: () => import('containers/UiElements/Avatars').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/badges',
					// 	lazy: () => import('containers/UiElements/Badges').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/accordion',
					// 	lazy: () => import('containers/UiElements/Accordion').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/list',
					// 	lazy: () => import('containers/UiElements/List').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/popover-tooltip',
					// 	lazy: () => import('containers/UiElements/PopoverTooltip').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/snackbar',
					// 	lazy: () => import('containers/UiElements/Snackbar').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/typography',
					// 	lazy: () => import('containers/UiElements/Typography').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/tabs',
					// 	lazy: () => import('containers/UiElements/Tabs').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/card-papper',
					// 	lazy: () => import('containers/UiElements/Cards').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/image-grid',
					// 	lazy: () => import('containers/UiElements/ImageGrid').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/progress',
					// 	lazy: () => import('containers/UiElements/Progress').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/dialog-modal',
					// 	lazy: () => import('containers/UiElements/DialogModal').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/steppers',
					// 	lazy: () => import('containers/UiElements/Steppers').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/paginations',
					// 	lazy: () => import('containers/UiElements/Paginations').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/drawer-menu',
					// 	lazy: () => import('containers/UiElements/DrawerMenu').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/breadcrumbs',
					// 	lazy: () => import('containers/UiElements/Breadcrumbs').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/icons',
					// 	lazy: () => import('containers/UiElements/Icons').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/ionicons',
					// 	lazy: () => import('containers/UiElements/IonIcons').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/slider-carousel',
					// 	lazy: () => import('containers/UiElements/SliderCarousel').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/tags',
					// 	lazy: () => import('containers/UiElements/Tags').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'ui/dividers',
					// 	lazy: () => import('containers/UiElements/Dividers').then(c => ({ Component: c.default })),
					// },

					// // Chart
					// {
					// 	path: 'charts',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/line-charts',
					// 	lazy: () => import('containers/Charts/LineCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/bar-charts',
					// 	lazy: () => import('containers/Charts/BarCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/area-charts',
					// 	lazy: () => import('containers/Charts/AreaCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/pie-charts',
					// 	lazy: () => import('containers/Charts/PieCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/radar-charts',
					// 	lazy: () => import('containers/Charts/RadarCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/scatter-charts',
					// 	lazy: () => import('containers/Charts/ScatterCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/compossed-chart',
					// 	lazy: () => import('containers/Charts/CompossedCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/doughnut-pie-charts',
					// 	lazy: () => import('containers/Charts/DoughnutCharts').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/bar-direction-charts',
					// 	lazy: () => import('containers/Charts/BarDirection').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/line-scatter-charts',
					// 	lazy: () => import('containers/Charts/LineScatterChart').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/area-filled-charts',
					// 	lazy: () => import('containers/Charts/AreaFilledChart').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'charts/radar-polar-chart',
					// 	lazy: () => import('containers/Charts/RadarPolarCharts').then(c => ({ Component: c.default })),
					// },

					// // Sample Apps
					// {
					// 	path: 'pages/checkout',
					// 	lazy: () => import('containers/SampleApps/Ecommerce/CheckoutPage').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/product-detail',
					// 	lazy: () => import('containers/SampleApps/Ecommerce/ProductPage').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/invoice',
					// 	lazy: () => import('containers/SampleApps/Invoice').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/taskboard',
					// 	lazy: () => import('containers/SampleApps/TaskBoard').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/calendar',
					// 	lazy: () => import('containers/SampleApps/Calendar').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/chat',
					// 	lazy: () => import('@Template/Chat'),
					// },
					// {
					// 	path: 'pages/contact',
					// 	lazy: () => import('@Template/Contact'),
					// },
					// {
					// 	path: 'pages/ecommerce',
					// 	lazy: () => import('containers/SampleApps/Ecommerce').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/timeline',
					// 	lazy: () => import('containers/SampleApps/Timeline').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/email',
					// 	lazy: () => import('containers/SampleApps/Email').then(c => ({ Component: c.default })),
					// },

					// // Pages
					// {
					// 	path: 'pages',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/user-profile',
					// 	lazy: () => import('containers/Pages/UserProfile').then(c => ({ Component: c.default as unknown as FC })),
					// },
					// {
					// 	path: 'pages/blank-page',
					// 	lazy: () => import('containers/Pages/BlankPage').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'blank-single',
					// 	lazy: () => import('containers/Pages/BlankPage').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/photo-gallery',
					// 	lazy: () => import('containers/Pages/Photos').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/pricing',
					// 	lazy: () => import('containers/Pages/Pricing').then(c => ({ Component: c.default })),
					// },


					// Sample Apps
					{
						path: 'pages/not-found',
						lazy: () => import('@Template/Error/404'),
					},
					// {
					// 	path: 'pages/error',
					// 	lazy: () => import('containers/Pages/Error').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/settings',
					// 	lazy: () => import('containers/Pages/Settings').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'pages/help-support',
					// 	lazy: () => import('containers/Pages/HelpSupport').then(c => ({ Component: c.default })),
					// },

					// // Map
					// {
					// 	path: 'maps',
					// 	lazy: () => import('containers/Parent').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'maps/map-marker',
					// 	lazy: () => import('containers/Maps/MapMarker').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'maps/map-direction',
					// 	lazy: () => import('containers/Maps/MapDirection').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'maps/map-searchbox',
					// 	lazy: () => import('containers/Maps/SearchMap').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'maps/map-traffic',
					// 	lazy: () => import('containers/Maps/TrafficIndicator').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: 'maps/street-view',
					// 	lazy: () => import('containers/Maps/StreetViewMap').then(c => ({ Component: c.default })),
					// },

					// Default
					{
						path: '*',
						lazy: () => import('@Template/Error/404'),
					},
				]
			},
			// {
			// 	path: '/blog',
			// 	lazy: () => import('containers/Templates/Blog').then(c => ({ Component: c.default })),
			// 	children: [
			// 		{
			// 			path: '',
			// 			lazy: () => import('containers/Pages/Blog').then(c => ({ Component: c.default })),
			// 		},
			// 		{
			// 			path: 'article',
			// 			lazy: () => import('containers/Pages/Blog/Article').then(c => ({ Component: c.default })),
			// 		}
			// 	]
			// },
			{
				// lazy: () => import('containers/Templates/Outer').then(c => ({ Component: c.default })),
				children: [
					// {
					// 	path: '/login',
					// 	lazy: () => import('containers/Pages/Users/Login').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: '/login-v2',
					// 	lazy: () => import('containers/Pages/Users/LoginV2').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: '/login-v3',
					// 	lazy: () => import('containers/Pages/Users/LoginV3').then(c => ({ Component: c.default })),
					// },

					// {
					// 	path: '/register',
					// 	lazy: () => import('containers/Pages/Users/Register').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: '/register-v2',
					// 	lazy: () => import('containers/Pages/Users/RegisterV2').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: '/register-v3',
					// 	lazy: () => import('containers/Pages/Users/RegisterV3').then(c => ({ Component: c.default })),
					// },

					// {
					// 	path: '/reset-password',
					// 	lazy: () => import('containers/Pages/Users/ResetPassword').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: '/lock-screen',
					// 	lazy: () => import('containers/Pages/Users/LockScreen').then(c => ({ Component: c.default })),
					// },
					// {
					// 	path: '/maintenance',
					// 	lazy: () => import('containers/Pages/Maintenance').then(c => ({ Component: c.default })),
					// },
					{
						path: 'coming-soon',
						lazy: () => import('@Template/Page/ComingSoon'),
					},
					{
						path: '404',
						lazy: () => import('@Template/Error/404'),
					},
				]
			},
			// {
			// 	path: '/*',
			// 	lazy: () => import('containers/Pages/Standalone/NotFoundDedicated').then(c => ({ Component: c.default })),
			// }
		]
	},
	// {
	// 	path: '/',
	// 	lazy: () => import('@Plugin/theme').then(({ ThemeProvider: Component }) => ({ Component })),
	// 	children: themeRoutes
	// },
]