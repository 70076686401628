import get from 'lodash/get';

import i18n from './i18n';
import { Language } from './languages';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const tryFindValue = (key: string, resources: Record<string, any>, codes: string[]) => {
	var output;

	for (const lng of codes) {
		if (lng in resources && resources[lng]) {
			output = get(resources[lng], key)
			if (output !== undefined) return output;
		}
	}

	return get(resources, key);
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\

var sorted: Array<string>;
export const tEx = i18n.tEx = (key, resources, defaultValue) => {
	var output;

  if (resources && typeof resources === 'object') {
		// Get sorted codes of supported languages
		if (!sorted || sorted[0] !== i18n.language) {
			sorted = Array.from(
				new Set([
					i18n.language,
					i18n.options.fallbackLng,
					i18n.options.supportedLngs
				].flat().map(c => `${!!c && typeof c === 'string' ? c : ''}`).filter(Boolean))
			);
		}

		output = (tryFindValue(key, resources, sorted) || tryFindValue(key, resources, Object.keys(resources)))
  }

  return output ?? (typeof resources === 'string' && resources) ?? defaultValue;
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

declare module 'i18next' {
  interface i18n {
    isRTL: (lng?: Language['code']) => boolean;
    tEx: (key: string, resources: Record<string, any>, defaultValue?: any) => string;
  }
}