import { memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import NProgress from 'nprogress';

import PreError from './PreError';
import { buildRoutes } from './routes';

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

// Configure NProgress
NProgress.configure({
  minimum: 0.2, // Minimum percentage before showing the progress bar
  easing: 'ease', // Easing animation
  speed: 500, // Animation speed in milliseconds
  showSpinner: false // Show or hide the spinner
});

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const Loading = memo(() => {
	const [ , setTimer ] = useState<NodeJS.Timeout>();

	useEffect(() => {
		// Start the NProgress on first loading
		setTimer(timer => {
			if (timer) {
				clearTimeout(timer);
			} else {
				NProgress.start();
			}

			return undefined;
		})

		return () => setTimer(setTimeout(NProgress.done, 1))
	}, []);

	return undefined;
})

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const ConfiguredRouterProvider = memo(() => {

	const { error, status, ...instance } = useSelector(state => state.app)

	const router = useMemo(() => {
		if (!instance.id) {
			return null;
		}

		// Create router
		const router = createBrowserRouter(buildRoutes(instance), { basename: '/' });

		// Listen for navigation changes
		router.subscribe((state) => {
			if (state.navigation.state !== 'idle') {
				NProgress.start();
			} else {
				NProgress.done();
			}
		})

		return router;
	}, [instance])

	// ** --------------------------------------------------------------------------------------------------------- ** \\
	// ** --------------------------------------------------------------------------------------------------------- ** \\

	return (instance.id || !error)
		? (!router
				? (
					<Loading />
				) : (
					<RouterProvider router={router} fallbackElement={<Loading />} />
				))
		: (
			<PreError error={error} isLoading={status === 'loading'} />
		)
});

export default ConfiguredRouterProvider;