export const randomString = (length = 8) => {
  let str = ''
  do {
    str += Math.random().toString(36).replace(/[^a-z0-9]+/g, '').replace(/^[0-9]+/, '')
  } while (str.length < length);
  return str.substring(0, length)
};

const reserved: Record<string, string> = {}
export const randomUnique = (length = 8) => {
  let str;
  do { str = randomString(length) } while (reserved[str])
  return reserved[str] = str;
}

const keyMap: Record<string, string> = {}
const rvsMap: Record<string, string> = {}
export const randomUniqueKey = (key: any, length = 8) => {
  if (keyMap[key]) return keyMap[key];
  do {
    keyMap[key] = randomString(8)
  } while (rvsMap[keyMap[key]])
  rvsMap[keyMap[key]] = key;
  return keyMap[key];
}