import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EnvironmentState, getEnvironmentalValue, setEnvironmentalValue } from '@Plugin/env';

import createAssignable, { AssignablePayload } from '../utils/createAssignable';

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const name = 'env'

const initialState: EnvState = {
	app: {},
	lng: {},

	sidebar: {},
};

const assignValues = createAssignable(initialState)

// ** ----------------------------------------------------------------------------------------------------------- ** \\

const getUserState = <K extends keyof EnvState>(state: EnvState, group: string, key: K, create: boolean) => {
	if (!state[key] || typeof state[key] !== 'object') {
		state[key] = {}
	}

	let userState = state[key][group];
	if (!userState || !Array.isArray(userState)) {
		if (create) {
			Object.assign(state[key], { [group]: userState = [] })
		} else {
			userState = []
		}
	}

	return userState
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const envSlice = createSlice({
  name,
  initialState,
  reducers: {
		assignEnvValuesAction: (state, action: PayloadAction<AssignablePayload<EnvState>>) => assignValues(state, action.payload),

		setEnvValue: (state, action: PayloadAction<EnvStatePayload>) => {
			const { key, env, group, value } = action.payload;

			setEnvironmentalValue(key, env, getUserState(state, group, key, true), value)
		
		}
  },
})

// Action creators are generated for each case reducer function
export const { assignEnvValuesAction, setEnvValue } = envSlice.actions

export default envSlice

// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const selectEnvValue = <K extends keyof EnvState>(state: EnvState, group: string, key: K, env: EnvironmentState[K]['e']): EnvironmentState[K]['v'] => {
	return getEnvironmentalValue(key, env, getUserState(state, group, key, false) )?.v
};

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\


type EnvState = {
	[K in keyof EnvironmentState]: Record<string, Array<EnvironmentState[K]>>  // Map<string, Array<EnvironmentState[K]>>
}

interface EnvStatePayload<K extends keyof EnvironmentState = keyof EnvironmentState> {
	key: K;
	env: EnvironmentState[K]['e'];
	value: EnvironmentState[K]['v'];
	group: string;
}