import { decrypt, encrypt, randomString } from './helpers';

Object.assign(String, {
	encrypt,
	decrypt,
	random: randomString,
	// randomUnique,
	// randomUniqueKey,
	// interpolate
})

Object.assign(String.prototype, {
	encrypt: () => encrypt((this || '').toString()),
	decrypt: () => decrypt((this || '').toString())	
})

declare global {
	interface String {
		encrypt: () => Promise<string | null>;
		decrypt: () => Promise<string | null>;
	}

	interface StringConstructor {
		encrypt: typeof encrypt;
		decrypt: typeof decrypt;
		random: typeof randomString;
	}
}