export const isObject = (value: any): value is object => Boolean(value && ['object', 'function'].indexOf(typeof value) > -1)

export const isPlainObject = (value: any): value is Record<string, any> => {
  if (!isObject(value))
    return false

  let proto = Object.getPrototypeOf(value)
  if (proto === null)
    return true

  while (Object.getPrototypeOf(proto) !== null)
    proto = Object.getPrototypeOf(proto)

  return Object.getPrototypeOf(value) === proto
}
