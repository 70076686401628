import { PropsWithChildren, memo } from 'react'
import { Provider} from 'react-redux';

import store from '.';

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const ConfiguredStoreProvider = memo<PropsWithChildren>(({ children }) => (
	<Provider store={store}>
		{children}
	</Provider>
));

export default ConfiguredStoreProvider;
