import { useMemo } from 'react'
import { useTranslation } from 'react-i18next';

export const useDirection = () => {

  const { i18n: { dir, language } } = useTranslation();
	
  const direction = useMemo(() => dir(language), [dir, language]);

	// ** --------------------------------------------------------------------------------------------------------- ** \\
	// ** --------------------------------------------------------------------------------------------------------- ** \\

	return {
		direction,
		isRTL: direction === 'rtl',
		isLTR: direction === 'ltr',
	}
}

export default useDirection;
