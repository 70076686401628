export const createParser = (reviver?: (this: any, key: string, value: any) => any) => (value: string | any, or?: any) => {
	if (typeof value === 'string') {
		try {
			return JSON.parse(value, reviver)
		} catch {
			return or
		}
	}

	return value || or
}