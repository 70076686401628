import merge from 'lodash/merge';


export const deepTruncate = (changes: Record<string, any>, current: Record<string, any>, ...primary: Array<Record<string, any>>) => {
	for (const key in changes) {
		let val = changes[key]

		if (Object.isObject(val) && !Array.isArray(val)) {
			const _current = Object.isObject(current[key]) || {}
			const _others = primary.map(o => Object.isObject(o[key]) || {})

			changes[key] = deepTruncate(val, _current, ..._others);
			if (Object.isObject(changes[key]) && Object.keys(changes[key]).length === 0)
				changes[key] = null

		} else if (Object.isEqual(val, current[key])) {
			delete changes[key]

		} else if ((key in current)) {
			if (Object.isEqual(val, current[key]))
				changes[key] = null

		} else {
			primary.some(o => {
				if (key in o) {
					if (!Object.isEqual(val, o[key]))
						return true;
					else
						changes[key] = null
				}
				return false;
			})
		}
	}

	const updated = merge({}, current)
	for (const key in changes) {
		if (changes[key] !== null)
			updated[key] = changes[key]
		else
			delete updated[key]
	}

	return updated;
}