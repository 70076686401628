import { createParser } from './helpers';

JSON.createParser = createParser
JSON.forceParse = createParser((key: string, value: any) => {
	if (typeof value === 'object' && value !== null) {
		if (value.__type__ === 'Map') {
			return new Map(value.value);
		}
		if (value.__type__ === 'Set') {
			return new Set(value.value);
		}
	}
	return value;
})

Object.assign(Set.prototype, {
	toJSON: function(this: Set<any>) {
		return {
			__type__: 'Set',
			value: [...this],
		};
	},
	fromJSON: function (json: CustomJSON<'Set', Array<any>>) {
		return new Set(json?.value);
	}
})

Object.assign(Map.prototype, {
	toJSON: function(this: Map<any, any>) {
		return {
			__type__: 'Map',
			value: [...this.entries()],
		};
	},
	fromJSON: function (json: CustomJSON<'Map', Array<[any, any]>>) {
		return new Map(json?.value);
	}
})

Object.assign(RegExp.prototype, {
	toJSON: function() {
		return String(this)
	}
})

Object.assign(Function.prototype, {
	toJSON: function() {
		return String(this)
	}
})

declare global {
	interface JSON {
		forceParse: (value: string | any, or?: any) => any;
		createParser(reviver?: (this: any, key: string, value: any) => any): (value: string | any, or?: any) => any;
	}

	type CustomJSON<T extends string, V> = {
		__type__: T;
		value: V;
	}

  interface Set<T> {
    toJSON(): CustomJSON<'Set', Array<T>>;
    fromJSON(json: CustomJSON<'Set', Array<T>>): Set<T>;
  }

	interface Map<K, V> {
		toJSON(): CustomJSON<'Map', Array<[K, V]>>;
		fromJSON(): CustomJSON<'Map', Array<[K, V]>>;
	}

	interface RegExp {
		toJSON(): string;
	}

	interface Function {
		toJSON(): string;
	}
}