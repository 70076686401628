import { FC, PropsWithChildren, useEffect } from 'react'
// import { useSelector } from 'react-redux';

import createCache from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';

import stylisRTLPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';

import { useDirection } from '@Plugin/i18n';
// import store, { assignUIValuesAction } from '@Plugin/store';

// import getPalette from './palette';

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

// Create rtl cache
const cacheRTL = createCache({
  key: 'rtl',
  stylisPlugins: [prefixer, stylisRTLPlugin],
});

// Create ltr cache
const cacheLTR = createCache({
  key: 'ltr',
});

// ** ----------------------------------------------------------------------------------------------------------- ** \\

// Configurations of [@media (prefers-color-scheme: dark)]
const prefersColorSchemeDark = window.matchMedia('(prefers-color-scheme: dark)');
const onPrefersColorSchemeChanged: (typeof prefersColorSchemeDark.onchange) = (e) => {
	// store.dispatch(assignUIValuesAction({
	// 	alterScheme: e.matches ? 'dark' : 'light',
	// }))
}

// ** ----------------------------------------------------------------------------------------------------------- ** \\
// ** ----------------------------------------------------------------------------------------------------------- ** \\

export const ConfiguredCacheProvider: FC<PropsWithChildren> = ({ children }) => {

  const { direction } = useDirection();

	// const ui = useSelector(state => state.ui)

	// ** --------------------------------------------------------------------------------------------------------- ** \\

	// const { colorScheme, paletteName } = useMemo(() => ({
	// 	paletteName: ui.paletteName,
	// 	colorScheme: ui.colorScheme || ui.alterScheme,
	// }), [ui])

	// const palette = useMemo(() => {
	// 	// returns with palette
	// 	return getPalette(paletteName, colorScheme, true)
	// }, [colorScheme, paletteName])

	// ** --------------------------------------------------------------------------------------------------------- ** \\

	useEffect(() => {

		// store.dispatch(assignUIValuesAction({
		// 	alterScheme: prefersColorSchemeDark.matches ? 'dark' : 'light',
		// }))

		// Listen to [@media (prefers-color-scheme: dark)]
		prefersColorSchemeDark.addEventListener('change', onPrefersColorSchemeChanged)

		return () => {
			// Remove listener of [@media (prefers-color-scheme: dark)]
			prefersColorSchemeDark.removeEventListener('change', onPrefersColorSchemeChanged)
		}
	}, [])

	// ** --------------------------------------------------------------------------------------------------------- ** \\
	// ** --------------------------------------------------------------------------------------------------------- ** \\

	return (
    <CacheProvider value={direction === 'rtl'  ? cacheRTL : cacheLTR}>
      <Global styles={{
        ':root': {
          // colorScheme,
          // '--bg-rgb': colorScheme === 'dark' ? '48,48,48' : '240,240,240',
          // '--major-rgb': palette.primary.main,
          // '--minor-rgb': palette.secondary.main,
        },
      }} />

      {children}
    </CacheProvider>
	);
}

export default ConfiguredCacheProvider;
