import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

import darkPalette from './darkPalette';
import lightPalette from './lightPalette';

import { PaletteName } from './enum';


const correctColors: TranslatePalette = (palette) => mapValues(palette, (value) => isObject(value) ? correctColors(value) : `rgb(${value})` )

const getPalette = (
  name: PaletteName | undefined,
  color: ColorScheme | undefined,
  native: boolean
) => {
  const palette = color === 'dark' ? darkPalette : lightPalette
  const scheme = (name as any in palette) ? palette[name ?? 'blue'] : Object.values(palette)[0]

  return native ? scheme : correctColors(scheme)
}

export default getPalette;

type ColorScheme = 'dark' | 'light';

interface PaletteColors {
  main: string,
  dark: string,
  light: string,
  contrastText: string,
}

type TranslatePalette<T = any> = (palette: T) => T;

export type ThemePalette = {
  [key in PaletteName]: {
    primary: PaletteColors;
    secondary: PaletteColors;
  }
}

export interface UIStyle {
  dense: boolean;

  bgDecor: 'petal' | 'none';
  bgStyle: 'solid' | 'gradient';
  bgReach: 'half' | 'full' | 'head';

  landingType: 'corporate' | 'creative',

  paletteName: PaletteName;
  colorScheme: ColorScheme | undefined;
  alterScheme: ColorScheme;
  layoutStyle: 'big-sidebar' | 'left-sidebar' | 'right-sidebar' | 'top-navigation' | 'mega-menu'

  showSidebar: boolean;
  expandMenus: string[];

  landpagePlatform?: string;
}