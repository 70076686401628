import '@Plugin/extensions'
import '@Plugin/loader'
import '@Plugin/i18n';

import 'sanitize.css';
import './dandelion/styles/layout/base.scss';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { HelmetProvider } from 'react-helmet-async';

import ConfiguredSWRProvider from '@Plugin/client/ConfiguredSWRProvider';
import ConfiguredStoreProvider from '@Plugin/store/ConfiguredStoreProvider';
import ConfiguredCacheProvider from '@Plugin/theme/ConfiguredCacheProvider';
import ConfiguredRouterProvider from '@Plugin/router/ConfiguredRouterProvider';

import reportWebVitals from './reportWebVitals';

/* eslint-disable import/no-webpack-loader-syntax */
// Load general files
import '!file-loader?name=[name]!@Asset/system/.htaccess';
import '!file-loader?name=[name].[ext]!@Asset/system/.nginx.conf';
// import '!file-loader?name=[name].[ext]!../public/favicons/favicon.ico';
/* eslint-enable import/no-webpack-loader-syntax */

// TODO:
// - Add dynamic meta tags or pre-render
// - Add uner construction page
// - Upload for test


// Using ts, react, i18n create context for multiple instances project:
// - The context will take area name
// - Each area has some of instances
// - the Context will provide area name, instances ids, method to switch between instances
// - Each instance has a separate i18n instance
// - This context is outer of the whole application
// - Each page has dynamic meta tags





const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <HelmetProvider>
      <ConfiguredSWRProvider>
        <ConfiguredStoreProvider>
          <ConfiguredCacheProvider>
            {/* <HubProvider>
            </HubProvider> */}
            <ConfiguredRouterProvider />
          </ConfiguredCacheProvider>
        </ConfiguredStoreProvider>
      </ConfiguredSWRProvider>
    </HelmetProvider>
  </StrictMode>
);


// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['./i18n', 'containers/App'], () => {
//     // root.unmount();
//     render(translationMessages);
//   });
// }

/**
Offline access for production mode.
Uncomment this code bellow to register Service Worker.
* */

// TODO: T-0006
//  if ('serviceWorker' in navigator) {
//    window.addEventListener('load', () => {
//      navigator.serviceWorker.register('/service-worker.js').then(registration => {
//        console.log('SW registered: ', registration);
//      }).catch(registrationError => {
//        console.log('SW registration failed: ', registrationError);
//      });
//    });
//  }

// TODO: T-0005
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.warn);