import APIStorage from './APIStorage';


export const apiStorage = APIStorage.getInstance();

export const argNameMap: Record<keyof URLArguments | string, string> = {
  index: 'i',
  limit: 'l',
  search: 'k',
  sortBy: 'o',
  params: 'p',
}

export const argDefault: Partial<URLArguments> = {
	index: 0,
	limit: 10,
	search: '',
}


interface OrderBy {
  name: string;
  desc: boolean;
  null?: undefined | 'first' | 'last';
}


export interface URLArguments {
	delay?: number;
	index?: number;
	limit?: number;
	search?: string;
	sortBy?: OrderBy[];
	params?: Record<string, any>;
}
